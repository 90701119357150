
export enum Secrets {
  NEXT_JS_PRIVATE_HASURA_PUBLICATIONS_ADMIN_SECRET = "NEXT_JS_PRIVATE_HASURA_PUBLICATIONS_ADMIN_SECRET",
  NEXT_JS_PRIVATE_HASURA_COACHES_ADMIN_SECRET = "NEXT_JS_PRIVATE_HASURA_COACHES_ADMIN_SECRET",
}

type MySecrets = {
  [secret in Secrets]: string
}

export const mySecrets: MySecrets = {
  NEXT_JS_PRIVATE_HASURA_PUBLICATIONS_ADMIN_SECRET: process.env.NEXT_JS_PRIVATE_HASURA_PUBLICATIONS_ADMIN_SECRET as string,
  NEXT_JS_PRIVATE_HASURA_COACHES_ADMIN_SECRET: process.env.NEXT_JS_PRIVATE_HASURA_COACHES_ADMIN_SECRET as string,
}


export const retrieveSecret = (secret: Secrets) => {
  if (process.browser) {
    return "";
  } else {
    return mySecrets[secret];
  }
};
