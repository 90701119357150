import {
  AllProgramsActions,
  AllProgramsSlice,
  ProgramState,
  AllProgramsActionTypes,
  AllProgramsBlacklisted,
} from "./all-programs.types";
import produce from "immer";
import { STORES } from "../stores";
import { storage, myPersistConfig } from "../storage";
import { HydrateActions } from "../helper";
import { HYDRATE } from "next-redux-wrapper";
import AuthActionTypes, { AuthActions } from "../auth/auth.types";

const programState: ProgramState = {
  document: "",
  program_id: "",
  publication_id: "",
};

const INITIAL_STATE: AllProgramsSlice = {
  programs: {
    byId: {},
    allIds: [],
  },
  loading: false,
  error: false,
};

const allProgramsReducer = (
  state = INITIAL_STATE,
  action: AllProgramsActions | HydrateActions | AuthActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HYDRATE: {
        break;
      }
      case AllProgramsActionTypes.ALL_PROGRAMS_FETCH_START: {
        draft.error = false;
        draft.loading = true;
        break;
      }
      case AllProgramsActionTypes.ALL_PROGRAMS_FETCH_SUCCESS: {
        const { programs } = action.payload;
        draft.error = false;
        draft.loading = false;
        draft.programs = produce(INITIAL_STATE.programs, (programsDraft) => {
          for (let i = 0; i < programs.length; i++) {
            const program = programs[i];
            programsDraft.byId[program.program_id] = { ...program };
            programsDraft.allIds.push(program.program_id);
          }
        });
        break;
      }
      case AllProgramsActionTypes.ALL_PROGRAMS_FETCH_FAILURE: {
        draft.error = true;
        draft.loading = false;
        break;
      }
      case AuthActionTypes.LOGOUT_SUCCESS: {
        return INITIAL_STATE;
      }
      default:
        return state;
    }
  });

export default myPersistConfig(allProgramsReducer, {
  key: STORES.ALL_PROGRAMS,
  storage: storage,
  blacklist: Object.values(AllProgramsBlacklisted),
});
