import produce from "immer";
import AuthActionTypes, { AuthSlice, AuthActions } from "./auth.types";
import { HYDRATE } from "next-redux-wrapper";
import { HydrateActions } from "../helper";

const INITIAL_STATE: AuthSlice = {
  isAuthenticated: false,
  isLoggingIn: false,
  isLoggingOut: false,
  isLoggedOut: true,
  user: undefined,
  tokenResults: null,
  tokenRefresherEnabled: false,
};

const authReducer = (
  state = INITIAL_STATE,
  action: AuthActions | HydrateActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HYDRATE: {
        break;
      }
      case AuthActionTypes.LOGIN_START: {
        draft.user = undefined;
        draft.isLoggingIn = true;
        draft.isAuthenticated = false;
        draft.isLoggingOut = false;
        draft.isLoggedOut = true;
        draft.tokenResults = null;
        break;
      }
      case AuthActionTypes.LOGIN_SUCCESS: {
        const { user, tokenResults } = action.payload;
        draft.user = user;
        draft.isLoggingIn = false;
        draft.isAuthenticated = true;
        draft.isLoggingOut = false;
        draft.isLoggedOut = false;
        draft.tokenResults = tokenResults;
        break;
      }
      case AuthActionTypes.LOGOUT_START: {
        draft.user = undefined;
        draft.isLoggingIn = false;
        draft.isAuthenticated = false;
        draft.isLoggingOut = true;
        draft.isLoggedOut = false;
        draft.tokenResults = null;
        break;
      }
      case AuthActionTypes.LOGOUT_SUCCESS: {
        return { ...INITIAL_STATE, tokenRefresherEnabled: true };
      }
      case AuthActionTypes.REFRESH_TOKEN_SUCCESS: {
        const { tokenResults } = action.payload;
        draft.tokenResults = tokenResults;
        break;
      }
      case AuthActionTypes.FIREBASE_REFRESH_USER_SUCCESS: {
        const { user, idTokenResult } = action.payload;
        draft.user = user;
        draft.isAuthenticated = true;
        draft.tokenResults = idTokenResult;
        draft.isLoggingIn = false;
        draft.isLoggingOut = false;
        draft.isLoggedOut = false;
        break;
      }
      default: {
        break;
      }
    }
  });

export default authReducer;
