export enum MyProgramsBlackListed {
  LOADING = "loading",
  ERROR = "error",
}

export interface Coach {
  name: string;
}

export interface Program {
  id: string;
  program_name: string;
  coach: Coach;
}

export interface UserToProgram {
  program: Program;
}

export interface MyProgramsSlice {
  programs: {
    byId: {
      [id: string]: Program;
    };
    allIds: string[];
  };
  [MyProgramsBlackListed.ERROR]: boolean;
  [MyProgramsBlackListed.LOADING]: boolean;
}

export enum MyProgramsActionTypes {
  GET_MY_PROGRAMS_START = "GET_MY_PROGRAMS_START",
  GET_MY_PROGRAMS_SUCCESS = "GET_MY_PROGRAMS_SUCCESS",
  GET_MY_PROGRAMS_FAILURE = "GET_MY_PROGRAMS_FAILURE",
}

export interface ActionGetMyProgramsStartPayload {}
export interface ActionGetMyProgramsStart {
  type: MyProgramsActionTypes.GET_MY_PROGRAMS_START;
  payload: ActionGetMyProgramsStartPayload;
}

export interface ActionGetMyProgramsSuccessPayload {
  users_to_programs: UserToProgram[];
}
export interface ActionGetMyProgramsSuccess {
  type: MyProgramsActionTypes.GET_MY_PROGRAMS_SUCCESS;
  payload: ActionGetMyProgramsSuccessPayload;
}

export interface ActionGetMyProgramsFailurePayload {
  error: Error;
}
export interface ActionGetMyProgramsFailure {
  type: MyProgramsActionTypes.GET_MY_PROGRAMS_FAILURE;
  payload: ActionGetMyProgramsFailurePayload;
}

export type MyProgramsActions =
  | ActionGetMyProgramsStart
  | ActionGetMyProgramsSuccess
  | ActionGetMyProgramsFailure;

export default MyProgramsActionTypes;
