import MyProgramsActionTypes, {
  ActionGetMyProgramsStart,
  ActionGetMyProgramsSuccessPayload,
  ActionGetMyProgramsSuccess,
  ActionGetMyProgramsFailurePayload,
  ActionGetMyProgramsFailure,
} from "./my-programs.types";

export const actionGetMyProgramsStart = (): ActionGetMyProgramsStart => ({
  type: MyProgramsActionTypes.GET_MY_PROGRAMS_START,
  payload: {},
});

export const actionGetMyProgramsSuccess = (
  payload: ActionGetMyProgramsSuccessPayload
): ActionGetMyProgramsSuccess => ({
  type: MyProgramsActionTypes.GET_MY_PROGRAMS_SUCCESS,
  payload,
});

export const actionGetMyProgramsFailure = (
  payload: ActionGetMyProgramsFailurePayload
): ActionGetMyProgramsFailure => ({
  type: MyProgramsActionTypes.GET_MY_PROGRAMS_FAILURE,
  payload,
});
