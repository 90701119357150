import produce from "immer";
import { LanguageActions, LanguageActionsTypes } from "./language.types";
import { Languages } from "../../Languages";
import { myPersistConfig, storage } from "../storage";
import { STORES } from "../stores";

const INITIAL_STATE = {
  languageId: Languages.Ids.FR,
};

const languageReducer = (state = INITIAL_STATE, action: LanguageActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LanguageActionsTypes.SET_LANGUAGE_ACTION_TYPE:
        draft.languageId = action.payload.languageId;
        break;
      default:
        return draft;
    }
  });

export default myPersistConfig(languageReducer, {
  key: STORES.LANGUAGE,
  storage: storage,
});
