export interface LoadingSlice {
  isLoading: boolean;
}

export enum LoadingActionTypes {
  SET_IS_LOADING = "SET_IS_LOADING",
}

export interface ActionSetIsLoadingPayload {
  isLoading: boolean;
}
export interface ActionSetIsLoading {
  type: LoadingActionTypes.SET_IS_LOADING;
  payload: ActionSetIsLoadingPayload;
}

export type LoadingActions = ActionSetIsLoading;

export default LoadingActionTypes;
