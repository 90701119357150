import { all, takeEvery, put, call } from "typed-redux-saga";
import { AllProgramsActionTypes, ProgramState } from "./all-programs.types";
import {
  actionAllProgramsFetchSuccess,
  actionAllProgramsFetchFailure,
} from "./all-programs.actions";
import hasuraPublications from "../../hasura-client";
import gql from "graphql-tag";
import Sentry from "../../third-party/sentry";

interface FetchAllProgramsReturn {
  programs: ProgramState[];
}
const fetchAllPrograms = async (): Promise<FetchAllProgramsReturn> => {
  const result = await hasuraPublications.query({
    query: gql`
      {
        program_public_pages(
          distinct_on: program_id
          order_by: { program_id: asc, created_at: desc }
        ) {
          document(path: "program_name")
          program_id
          publication_id
        }
      }
    `,
    variables: {},
  });

  const data = result.data as {
    program_public_pages: ProgramState[];
  };

  return { programs: data.program_public_pages };
};

function* onFetchAllProgramsStart() {
  yield* takeEvery(
    AllProgramsActionTypes.ALL_PROGRAMS_FETCH_START,
    function* () {
      try {
        const { programs }: FetchAllProgramsReturn = yield* call(
          fetchAllPrograms
        );

        yield* put(
          actionAllProgramsFetchSuccess({
            programs: programs,
          })
        );
      } catch (error) {
        Sentry.captureException(error);
        yield* put(actionAllProgramsFetchFailure({ error }));
      }
    }
  );
}

export function* allProgramsSagas() {
  yield* all([call(onFetchAllProgramsStart)]);
}
