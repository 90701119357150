import produce from "immer";
import MyProgramsActionTypes, {
  MyProgramsSlice,
  MyProgramsActions,
  MyProgramsBlackListed,
} from "./my-programs.types";
import { HydrateActions } from "../helper";
import { HYDRATE } from "next-redux-wrapper";
import { myPersistConfig, storage } from "../storage";
import { STORES } from "../stores";
import AuthActionTypes, { AuthActions } from "../auth/auth.types";
import {
  FreeCheckoutActionTypes,
  FreeCheckoutActions,
} from "../free-checkout/free-checkout.types";

const INITIAL_STATE: MyProgramsSlice = {
  programs: {
    byId: {},
    allIds: [],
  },
  loading: false,
  error: false,
};

const myProgramsReducer = (
  state = INITIAL_STATE,
  action: MyProgramsActions | HydrateActions | AuthActions | FreeCheckoutActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HYDRATE: {
        break;
      }
      case MyProgramsActionTypes.GET_MY_PROGRAMS_FAILURE: {
        draft.programs = {
          byId: {},
          allIds: [],
        };
        draft.error = true;
        draft.loading = false;
        break;
      }
      case MyProgramsActionTypes.GET_MY_PROGRAMS_START: {
        draft.programs = {
          byId: {},
          allIds: [],
        };
        draft.error = false;
        draft.loading = true;
        break;
      }
      case MyProgramsActionTypes.GET_MY_PROGRAMS_SUCCESS: {
        const { users_to_programs } = action.payload;

        draft.programs = produce(INITIAL_STATE.programs, (programsDraft) => {
          for (let i = 0; i < users_to_programs.length; i++) {
            const { program } = users_to_programs[i];
            const { id, program_name, coach } = program;
            programsDraft.byId[id] = {
              id,
              program_name,
              coach,
            };
            programsDraft.allIds.push(id);
          }
        });
        draft.error = false;
        draft.loading = false;
        break;
      }
      case FreeCheckoutActionTypes.FREE_CHECKOUT_PROGRAM_SUCCESS: {
        const { programId, coach, programName } = action.payload;

        draft.programs.byId[programId] = {
          id: programId,
          program_name: programName,
          coach,
        };
        if (!draft.programs.allIds.includes(programId))
          draft.programs.allIds.push(programId);
        break;
      }
      case AuthActionTypes.LOGOUT_SUCCESS: {
        return INITIAL_STATE;
      }
      default: {
        break;
      }
    }
  });

export default myPersistConfig(myProgramsReducer, {
  key: STORES.MY_PROGRAMS,
  storage: storage,
  blacklist: Object.values(MyProgramsBlackListed),
});
