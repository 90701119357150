import HasuraPublicationActionTypes, {
  ActionIdentifyToHasuraPublicationStartPayload,
  ActionIdentifyToHasuraPublicationStart,
  ActionIdentifyToHasuraPublicationSuccessPayload,
  ActionIdentifyToHasuraPublicationSuccess,
  ActionIdentifyToHasuraPublicationFailurePayload,
  ActionIdentifyToHasuraPublicationFailure,
} from "./hasura-publications.types";

export const actionIdentifyToHasuraPublicationStart = (
  payload: ActionIdentifyToHasuraPublicationStartPayload
): ActionIdentifyToHasuraPublicationStart => ({
  type: HasuraPublicationActionTypes.IDENTIFY_TO_HASURA_PUBLICATION_START,
  payload,
});

export const actionIdentifyToHasuraPublicationSuccess = (
  payload: ActionIdentifyToHasuraPublicationSuccessPayload
): ActionIdentifyToHasuraPublicationSuccess => ({
  type: HasuraPublicationActionTypes.IDENTIFY_TO_HASURA_PUBLICATION_SUCCESS,
  payload,
});

export const actionIdentifyToHasuraPublicationFailure = (
  payload: ActionIdentifyToHasuraPublicationFailurePayload
): ActionIdentifyToHasuraPublicationFailure => ({
  type: HasuraPublicationActionTypes.IDENTIFY_TO_HASURA_PUBLICATION_FAILURE,
  payload,
});
