import produce from "immer";
import FreeCheckoutActionTypes, {
  FreeCheckoutSlice,
  FreeCheckoutState,
  FreeCheckoutActions,
} from "./free-checkout.types";
import { HydrateActions } from "../helper";
import { HYDRATE } from "next-redux-wrapper";
import AuthActionTypes, { AuthActions } from "../auth/auth.types";

const freeCheckoutState: FreeCheckoutState = {
  isCheckingOut: false,
  error: false,
  status: "",
};

const INITIAL_STATE: FreeCheckoutSlice = {
  byId: {},
  allIds: [],
};

const freeCheckoutReducer = (
  state = INITIAL_STATE,
  action: FreeCheckoutActions | HydrateActions | AuthActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HYDRATE: {
        break;
      }
      case FreeCheckoutActionTypes.FREE_CHECKOUT_PROGRAM_START: {
        const { programId } = action.payload;

        draft.byId[programId] = {
          isCheckingOut: true,
          error: false,
          status: "NOT_STARTED",
        };

        draft.allIds.push(programId);

        break;
      }
      case FreeCheckoutActionTypes.FREE_CHECKOUT_PROGRAM_SUCCESS: {
        const { programId, status } = action.payload;

        draft.byId[programId] = {
          isCheckingOut: false,
          error: false,
          status: status,
        };

        break;
      }
      case FreeCheckoutActionTypes.FREE_CHECKOUT_PROGRAM_FAILURE: {
        const { programId, status } = action.payload;

        draft.byId[programId] = {
          isCheckingOut: false,
          error: true,
          status: status,
        };

        break;
      }
      case FreeCheckoutActionTypes.SET_FREE_CHECKOUT_PROGRAM_STATUS: {
        const { id, status } = action.payload;
        draft.byId[id].status = status;
        break;
      }
      case AuthActionTypes.LOGOUT_SUCCESS: {
        return INITIAL_STATE;
      }
      default:
        break;
    }
  });

export default freeCheckoutReducer;
