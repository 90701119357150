import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./root.reducer";
import { persistStore } from "redux-persist";
import rootSaga from "./root.saga";
import logger from "redux-logger";
import { BlackListedStores } from "./stores";
import { MakeStore, createWrapper, Context } from "next-redux-wrapper";
import createSentryMiddleware from "redux-sentry-middleware";
import * as Sentry from "@sentry/node";

export const makeStore: MakeStore<any> = (context: Context) => {
  const isServer = typeof window === "undefined";
  let store;
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, errorInfo) => {
      Sentry.captureException(error);
    },
  });
  // @ts-ignore
  const sentryMiddleware = createSentryMiddleware(Sentry, {});

  if (!isServer) {
    const { persistReducer } = require("redux-persist");
    const { storage } = require("./storage");

    const persistConfig = {
      key: "root",
      storage,
      blacklist: BlackListedStores,
    };
    const middlewares = [sagaMiddleware, sentryMiddleware];

    if (process.env.NODE_ENV === "development") {
      middlewares.push(logger);
    }

    let composeEnhancers =
      // @ts-ignore
      compose;

    if (process.env.NODE_ENV === "development") {
      composeEnhancers =
        // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    }

    store = createStore(
      persistReducer(persistConfig, rootReducer),
      composeEnhancers(applyMiddleware(...middlewares))
    );
    // @ts-ignore
    store.__PERSISTOR = persistStore(store);
  } else {
    store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
  }
  if (!isServer) {
    // @ts-ignore
    store.sagaTask = sagaMiddleware.run(rootSaga);
  }
  return store;
};

export const wrapper = createWrapper<any>(makeStore, { debug: false });
