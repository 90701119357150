import CheckoutActionTypes, {
  ActionCheckoutProgramStartPayload,
  ActionCheckoutProgramStart,
  ActionCheckoutProgramSuccessPayload,
  ActionCheckoutProgramSuccess,
  ActionCheckoutProgramFailurePayload,
  ActionCheckoutProgramFailure,
} from "./checkout.types";

export const actionCheckoutStart = (
  payload: ActionCheckoutProgramStartPayload
): ActionCheckoutProgramStart => ({
  type: CheckoutActionTypes.CHECKOUT_PROGRAM_START,
  payload,
});

export const actionCheckoutSuccess = (
  payload: ActionCheckoutProgramSuccessPayload
): ActionCheckoutProgramSuccess => ({
  type: CheckoutActionTypes.CHECKOUT_PROGRAM_SUCCESS,
  payload,
});

export const actionCheckoutFailure = (
  payload: ActionCheckoutProgramFailurePayload
): ActionCheckoutProgramFailure => ({
  type: CheckoutActionTypes.CHECKOUT_PROGRAM_FAILURE,
  payload,
});
