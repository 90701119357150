// This import is to prevent https://stackoverflow.com/questions/50688998/using-apolloclient-with-node-js-fetch-is-not-found-globally-and-no-fetcher-pas
import "cross-fetch/polyfill";
import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { WebSocketLink } from "apollo-link-ws";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { setContext } from "apollo-link-context";
import { STOREKEY } from "next-redux-wrapper";
import { retrieveSecret, Secrets } from "./secrets";

export const hasuraPublicationUrlHttps = `https://${process.env.NEXT_PUBLIC_DONOTSKIP_PROGRAMS_HASURA_DONOTSKIP_PUBLICATIONS_DOMAIN}/v1/graphql`;
export const hasuraPublicationUrlWs = `wss://${process.env.NEXT_PUBLIC_DONOTSKIP_PROGRAMS_HASURA_DONOTSKIP_PUBLICATIONS_DOMAIN}/v1/graphql`;

const retrieveToken = () => {
  if (process.browser) {
    // get the authentication token from local storage if it exists

    // @ts-ignore
    const store = window[STOREKEY].getState();

    const { authManager } = store;

    const { tokenResults } = authManager;

    const { token } = tokenResults;

    return token;
  } else {
    return "";
  }
};

export const createApolloClient = () => {
  const httpLink = new HttpLink({
    uri: hasuraPublicationUrlHttps,
  });

  const httpAuthLink = setContext((_, { headers }) => {
    const token = retrieveToken();

    // return the headers to the context so httpLink can read them
    if (token) {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }

    return headers;
  });

  const httpAdminLink = setContext((_, { headers }) => {
    const adminSecret = retrieveSecret(
      Secrets.NEXT_JS_PRIVATE_HASURA_PUBLICATIONS_ADMIN_SECRET
    );

    // return the headers to the context so httpLink can read them
    if (adminSecret) {
      return {
        headers: {
          ...headers,
          "x-hasura-admin-secret": adminSecret,
        },
      };
    }

    return headers;
  });

  let webSocketLink = null;

  if (process.browser) {
    const wsLink = new WebSocketLink({
      uri: hasuraPublicationUrlWs,
      options: {
        lazy: true,
        reconnect: true,
        connectionParams: async () => {
          return {
            headers: {
              Authorization: `Bearer ${retrieveToken()}`,
            },
          };
        },
      },
    });
    webSocketLink = wsLink;
  }

  const link = process.browser
    ? split(
        // split based on operation type
        ({ query }) => {
          const { kind, operation } = getMainDefinition(query) as {
            kind: string;
            operation: string;
          };
          return kind === "OperationDefinition" && operation === "subscription";
        },
        webSocketLink as WebSocketLink,
        httpAuthLink.concat(httpLink)
      )
    : httpAdminLink.concat(httpLink);

  return new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
  });
};

let hasuraPublications = createApolloClient();

export const resetHasuraPublications = () => {
  hasuraPublications = createApolloClient();
};

export default hasuraPublications;
