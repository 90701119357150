import { all, takeEvery, call, put } from "typed-redux-saga";
import {
  actionIdentifyToHasuraPublicationSuccess,
  actionIdentifyToHasuraPublicationStart,
  actionIdentifyToHasuraPublicationFailure,
} from "./hasura-publications.actions";
import HasuraPublicationActionTypes, {
  ActionIdentifyToHasuraPublicationStart,
} from "./hasura-publications.types";
import AuthActionTypes, { ActionAuthLoginSuccess } from "../auth/auth.types";
import { actionAuthLogoutStart } from "../auth/auth.actions";
import gql from "graphql-tag";
import Sentry from "../../third-party/sentry";
import hasuraPublications, {
  resetHasuraPublications,
} from "../../hasura-client";
import {
  IdentifyToHasuraPublicationMutation,
  IdentifyToHasuraPublicationMutationVariables,
  IdentifyToHasuraPublication,
} from "../../graphql/donotskip-publications.types";
import { snackbarRef } from "../../third-party/snackBarService";
import { i18n } from "../../../i18n";

export const identifyToHasuraPublicationsAsync = async (
  email: string,
  name: string | null
) => {
  const { data } = await hasuraPublications.mutate<
    IdentifyToHasuraPublicationMutation,
    IdentifyToHasuraPublicationMutationVariables
  >({
    mutation: IdentifyToHasuraPublication,
    variables: {
      email,
      name,
    },
  });

  return data;
};

export function* identifyToHasuraPublicationsStart({
  payload,
}: ActionIdentifyToHasuraPublicationStart) {
  const { userId, token, email, name } = payload;
  try {
    yield* call(resetHasuraPublications);

    if (!email) {
      throw Error("Email is not defined!");
    }

    const data = yield* call(identifyToHasuraPublicationsAsync, email, name);

    if (!data) {
      throw Error("Data is not defined!");
    }

    const { insert_users } = data;

    if (data?.insert_users?.affected_rows !== 1) {
      throw Error("Can't identify to hasura publications");
    }

    yield* put(actionIdentifyToHasuraPublicationSuccess({ userId }));
  } catch (error) {
    Sentry.captureException(error);
    yield* put(actionIdentifyToHasuraPublicationFailure({ error, userId }));
  }
}

export function* onIdentifyToHasuraPublicationStart() {
  yield* takeEvery(
    HasuraPublicationActionTypes.IDENTIFY_TO_HASURA_PUBLICATION_START,
    identifyToHasuraPublicationsStart
  );
}

export function* onLoginToFirebaseSuccess() {
  yield* takeEvery(AuthActionTypes.LOGIN_SUCCESS, function* ({
    payload,
  }: ActionAuthLoginSuccess) {
    const { user } = payload;
    const { uid, email, displayName } = user;
    const token = yield* call([user, user.getIdToken]);
    yield* put(
      actionIdentifyToHasuraPublicationStart({
        userId: uid,
        token,
        email,
        name: displayName,
      })
    );
  });
}

export function* onIdentifyToHasuraPublicationFailure() {
  yield* takeEvery(
    HasuraPublicationActionTypes.IDENTIFY_TO_HASURA_PUBLICATION_FAILURE,
    function* () {
      snackbarRef.enqueueSnackbar(i18n.t("Error while logging in"), {
        variant: "error",
      });
      yield* put(actionAuthLogoutStart());
    }
  );
}

export function* hasuraPublicationsSagas() {
  yield* all([
    call(onLoginToFirebaseSuccess),
    call(onIdentifyToHasuraPublicationStart),
    call(onIdentifyToHasuraPublicationFailure),
  ]);
}
