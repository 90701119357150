export enum AllProgramsBlacklisted {
  LOADING = "loading",
  ERROR = "error",
}

export interface ProgramState {
  document: string;
  program_id: string;
  publication_id: string;
}

export interface Programs {
  byId: {
    [id: string]: ProgramState;
  };
  allIds: string[];
}

export interface AllProgramsSlice {
  programs: Programs;
  [AllProgramsBlacklisted.LOADING]: boolean;
  [AllProgramsBlacklisted.ERROR]: boolean;
}

export enum AllProgramsActionTypes {
  ALL_PROGRAMS_FETCH_START = "ALL_PROGRAMS_FETCH_START",
  ALL_PROGRAMS_FETCH_SUCCESS = "ALL_PROGRAMS_FETCH_SUCCESS",
  ALL_PROGRAMS_FETCH_FAILURE = "ALL_PROGRAMS_FETCH_FAILURE",
}

export interface ActionAllProgramsFetchStartPayload {}
export interface ActionAllProgramsFetchStart {
  type: AllProgramsActionTypes.ALL_PROGRAMS_FETCH_START;
  payload: ActionAllProgramsFetchStartPayload;
}

export interface ActionAllProgramsFetchSuccessPayload {
  programs: ProgramState[];
}
export interface ActionAllProgramsFetchSuccess {
  type: AllProgramsActionTypes.ALL_PROGRAMS_FETCH_SUCCESS;
  payload: ActionAllProgramsFetchSuccessPayload;
}

export interface ActionAllProgramsFetchFailurePayload {
  error: Error;
}
export interface ActionAllProgramsFetchFailure {
  type: AllProgramsActionTypes.ALL_PROGRAMS_FETCH_FAILURE;
  payload: ActionAllProgramsFetchFailurePayload;
}

export type AllProgramsActions =
  | ActionAllProgramsFetchStart
  | ActionAllProgramsFetchSuccess
  | ActionAllProgramsFetchFailure;
