import produce from "immer";
import StripeActionTypes from "./stripe.types";

const INITIAL_STATE = {
  // Must be NULL and not undefined for StripeProvider
  client: null
};

const stripeReducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case StripeActionTypes.SET_STRIPE_CLIENT: {
        const { client } = payload;
        draft.client = client;
        break;
      }
      default: {
        break;
      }
    }
  });

export default stripeReducer;
