import { Coach } from "../my-programs/my-programs.types";

export interface FreeCheckoutState {
  isCheckingOut: boolean;
  error: boolean;
  status: string;
}

export interface FreeCheckoutSlice {
  byId: {
    [id: string]: FreeCheckoutState;
  };
  allIds: string[];
}

export enum FreeCheckoutActionTypes {
  FREE_CHECKOUT_PROGRAM_START = "FREE_CHECKOUT_PROGRAM_START",
  FREE_CHECKOUT_PROGRAM_SUCCESS = "FREE_CHECKOUT_PROGRAM_SUCCESS",
  FREE_CHECKOUT_PROGRAM_FAILURE = "FREE_CHECKOUT_PROGRAM_FAILURE",
  SET_FREE_CHECKOUT_PROGRAM_STATUS = "SET_FREE_CHECKOUT_PROGRAM_STATUS",
}

export interface ActionFreeCheckoutProgramStartPayload {
  programId: string;
  email: string;
}
export interface ActionFreeCheckoutProgramStart {
  type: FreeCheckoutActionTypes.FREE_CHECKOUT_PROGRAM_START;
  payload: ActionFreeCheckoutProgramStartPayload;
}

export interface ActionFreeCheckoutProgramSuccessPayload {
  programId: string;
  email: string;
  status: string;
  programName: string;
  coach: Coach;
}
export interface ActionFreeCheckoutProgramSuccess {
  type: FreeCheckoutActionTypes.FREE_CHECKOUT_PROGRAM_SUCCESS;
  payload: ActionFreeCheckoutProgramSuccessPayload;
}

export interface ActionFreeCheckoutProgramFailurePayload {
  programId: string;
  email: string;
  error: Error;
  status: string;
}
export interface ActionFreeCheckoutProgramFailure {
  type: FreeCheckoutActionTypes.FREE_CHECKOUT_PROGRAM_FAILURE;
  payload: ActionFreeCheckoutProgramFailurePayload;
}

export interface ActionSetFreeCheckoutStatusPayload {
  id: string;
  status: string;
}
export interface ActionSetFreeCheckoutStatus {
  type: FreeCheckoutActionTypes.SET_FREE_CHECKOUT_PROGRAM_STATUS;
  payload: ActionSetFreeCheckoutStatusPayload;
}

export type FreeCheckoutActions =
  | ActionFreeCheckoutProgramStart
  | ActionFreeCheckoutProgramSuccess
  | ActionFreeCheckoutProgramFailure
  | ActionSetFreeCheckoutStatus;

export default FreeCheckoutActionTypes;
