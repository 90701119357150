import { all, takeEvery, call, put, take, select } from "typed-redux-saga";
import CheckoutActionTypes, {
  ActionCheckoutProgramStart,
} from "./checkout.types";
import {
  actionCheckoutSuccess,
  actionCheckoutFailure,
} from "./checkout.actions";
import { eventChannel } from "redux-saga";
import gql from "graphql-tag";
import CheckoutError from "../../Errors/CheckoutError";
import Sentry from "../../third-party/sentry";
import hasuraPublications from "../../hasura-client";
import {
  InsertCreateStripeCheckoutSession,
  InsertCreateStripeCheckoutSessionMutation,
  InsertCreateStripeCheckoutSessionMutationVariables,
  CreateStripeCheckoutSession,
  CreateStripeCheckoutSessionSubscription,
  CreateStripeCheckoutSessionSubscriptionVariables,
  Create_Stripe_Checkout_Session_Statuses_Enum,
  StripeCheckoutSession,
  StripeCheckoutSessionSubscription,
  StripeCheckoutSessionSubscriptionVariables,
} from "../../graphql/donotskip-publications.types";

const createCreateCheckoutSessionChannel = (id: string) =>
  eventChannel<Error | Create_Stripe_Checkout_Session_Statuses_Enum>(
    (emitter) => {
      const observable = hasuraPublications.subscribe<
        CreateStripeCheckoutSessionSubscription,
        CreateStripeCheckoutSessionSubscriptionVariables
      >({
        query: CreateStripeCheckoutSession,
        variables: {
          id: id,
        },
      });

      const subscription = observable.subscribe({
        next: (value) => {
          if (value) {
            const { data } = value;
            if (data) {
              const { create_stripe_checkout_sessions } = data;

              const stripe_checkout_session =
                create_stripe_checkout_sessions[0];

              if (stripe_checkout_session) {
                const { status } = stripe_checkout_session;

                if (status !== "IN_PROGRESS") emitter(status);
              }
            }
          }
        },
        error: (error) => emitter(new Error(error.message)),
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  );

const createCheckoutChannel = (id: string) =>
  eventChannel<string | Error>((emitter) => {
    const observable = hasuraPublications.subscribe<
      StripeCheckoutSessionSubscription,
      StripeCheckoutSessionSubscriptionVariables
    >({
      query: StripeCheckoutSession,
      variables: {
        id: id,
      },
    });

    const subscription = observable.subscribe({
      next: (value) => {
        if (value) {
          const { data } = value;
          if (data) {
            const { stripe_checkout_sessions } = data;

            const stripe_checkout_session = stripe_checkout_sessions[0];

            if (stripe_checkout_session) {
              const { stripe_checkout_session_id } = stripe_checkout_session;

              emitter(stripe_checkout_session_id);
            }
          }
        }
      },
      error: (error) => emitter(new Error(error.message)),
    });

    return () => {
      subscription.unsubscribe();
    };
  });

export const checkoutAsync = async (programId: string) => {
  const { data } = await hasuraPublications.mutate<
    InsertCreateStripeCheckoutSessionMutation,
    InsertCreateStripeCheckoutSessionMutationVariables
  >({
    mutation: InsertCreateStripeCheckoutSession,
    variables: {
      program_id: programId,
    },
  });

  return data;
};

export function* checkoutStart({ payload }: ActionCheckoutProgramStart) {
  const { programId, email } = payload;
  try {
    const data = yield* call(checkoutAsync, programId);

    const databaseId =
      data?.insert_create_stripe_checkout_sessions?.returning[0]?.id;

    if (!databaseId) {
      throw new CheckoutError("ERROR", "Database ID is not defined!");
    }

    const checkoutSessionCreationChannel = yield* call(
      createCreateCheckoutSessionChannel,
      databaseId
    );

    const status = (yield* take(
      checkoutSessionCreationChannel
    )) as Create_Stripe_Checkout_Session_Statuses_Enum;

    if (status !== "DONE") {
      throw new CheckoutError(
        status,
        `Could not create checkout session (status: ${status})`
      );
    }

    const checkoutChannel = yield* call(createCheckoutChannel, databaseId);

    const stripe_checkout_session_id = (yield* take(checkoutChannel)) as string;

    checkoutChannel.close();

    yield* put(
      actionCheckoutSuccess({
        programId,
        email,
        stripeCheckoutSessionId: stripe_checkout_session_id,
        status,
      })
    );
  } catch (error) {
    Sentry.captureException(error);
    const status = error.status;
    yield* put(actionCheckoutFailure({ error, programId, email, status }));
  }
}

export function* onCheckoutStart() {
  yield* takeEvery(CheckoutActionTypes.CHECKOUT_PROGRAM_START, checkoutStart);
}

export function* checkoutSagas() {
  yield* all([call(onCheckoutStart)]);
}
