import * as Sentry from "@sentry/node";
import { envVariables } from "../yup.env.schema";

export const SentryDSN =
  process.env.NEXT_PUBLIC_DONOTSKIP_PROGRAMS_SENTRY_DSN;

export const setSentryScopeUser = (user: firebase.User) => {
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: user.uid,
      email: user.email ?? undefined,
      username: user.displayName ?? undefined,
    });
  });
};

export default Sentry;
