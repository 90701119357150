import LoadingActionTypes, {
  ActionSetIsLoadingPayload,
  ActionSetIsLoading,
} from "./loading.types";

export const actionSetIsLoading = (
  payload: ActionSetIsLoadingPayload
): ActionSetIsLoading => ({
  type: LoadingActionTypes.SET_IS_LOADING,
  payload,
});
