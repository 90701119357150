import { all, takeEvery, call, put, take, select } from "typed-redux-saga";
import FreeCheckoutActionTypes, {
  ActionFreeCheckoutProgramStart,
} from "./free-checkout.types";
import {
  actionFreeCheckoutSuccess,
  actionFreeCheckoutFailure,
  actionSetFreeCheckoutStatus,
} from "./free-checkout.actions";
import { eventChannel } from "redux-saga";
import gql from "graphql-tag";
import FreeCheckoutError from "../../Errors/FreeCheckoutError";
import Sentry from "../../third-party/sentry";
import hasuraPublications from "../../hasura-client";
import {
  SubscribeToFreeCheckoutSessionCreation,
  SubscribeToFreeCheckoutSessionCreationSubscription,
  SubscribeToFreeCheckoutSessionCreationSubscriptionVariables,
  Create_Free_Checkout_Sessions,
  InsertCreateFreeCheckoutSessions,
  InsertCreateFreeCheckoutSessionsMutation,
  InsertCreateFreeCheckoutSessionsMutationVariables,
} from "../../graphql/donotskip-publications.types";

const createCreateFreeCheckoutSessionChannel = (id: string) =>
  eventChannel<Error | Pick<Create_Free_Checkout_Sessions, "status" | "over">>(
    (emitter) => {
      const observable = hasuraPublications.subscribe<
        SubscribeToFreeCheckoutSessionCreationSubscription,
        SubscribeToFreeCheckoutSessionCreationSubscriptionVariables
      >({
        query: SubscribeToFreeCheckoutSessionCreation,
        variables: {
          id: id,
        },
      });

      const subscription = observable.subscribe({
        next: (value) => {
          if (value) {
            const { data } = value;

            if (data) {
              const { create_free_checkout_sessions } = data;

              const create_free_checkout_session =
                create_free_checkout_sessions[0];

              if (create_free_checkout_session) {
                emitter(create_free_checkout_session);
              }
            }
          }
        },
        error: (error) => emitter(new Error(error.message)),
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  );

export const insertFreeCheckoutSessionAsync = async (programId: string) => {
  const { data } = await hasuraPublications.mutate<
    InsertCreateFreeCheckoutSessionsMutation,
    InsertCreateFreeCheckoutSessionsMutationVariables
  >({
    mutation: InsertCreateFreeCheckoutSessions,
    variables: {
      program_id: programId,
    },
  });

  return data;
};

export function* freeCheckoutStart({
  payload,
}: ActionFreeCheckoutProgramStart) {
  const { programId, email } = payload;
  try {
    const data = yield* call(insertFreeCheckoutSessionAsync, programId);
    const returning = data?.insert_create_free_checkout_sessions?.returning[0];

    if (!returning) {
      throw new Error("Returning is not defined");
    }

    const databaseId = returning.id;

    const coach = returning.program.coach;

    const programName = returning.program.program_name;

    if (!databaseId) {
      throw new FreeCheckoutError("ERROR", "Database ID is not defined!");
    }

    if (!coach) {
      throw new Error("Coach is not defined");
    }

    if (!programName) {
      throw new Error("Program Name is not defined");
    }

    const checkoutSessionCreationChannel = yield* call(
      createCreateFreeCheckoutSessionChannel,
      databaseId
    );

    let create_free_checkout_session = (yield* take(
      checkoutSessionCreationChannel
    )) as Pick<Create_Free_Checkout_Sessions, "status" | "over">;
    while (
      !create_free_checkout_session ||
      create_free_checkout_session.over === false
    ) {
      create_free_checkout_session = (yield* take(
        checkoutSessionCreationChannel
      )) as Pick<Create_Free_Checkout_Sessions, "status" | "over">;
      yield* put(
        actionSetFreeCheckoutStatus({
          id: programId,
          status: create_free_checkout_session.status,
        })
      );
    }

    yield* put(
      actionFreeCheckoutSuccess({
        programId,
        email,
        status: create_free_checkout_session.status,
        programName,
        coach,
      })
    );
  } catch (error) {
    Sentry.captureException(error);
    const status = error.status;
    yield* put(actionFreeCheckoutFailure({ error, programId, email, status }));
  }
}

export function* onFreeCheckoutStart() {
  yield* takeEvery(
    FreeCheckoutActionTypes.FREE_CHECKOUT_PROGRAM_START,
    freeCheckoutStart
  );
}

export function* freeCheckoutSagas() {
  yield* all([call(onFreeCheckoutStart)]);
}
