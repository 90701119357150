import produce from "immer";
import { AnyAction } from "redux";
import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {};

const hasuraPublicationsReducer = (state = INITIAL_STATE, action: AnyAction) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HYDRATE: {
        break;
      }
      default: {
        break;
      }
    }
  });

export default hasuraPublicationsReducer;
