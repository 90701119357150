import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  jsonb: any;
  timestamptz: string;
  uuid: string;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "coaches" */
export type Coaches = {
  __typename?: 'coaches';
  id: Scalars['String'];
  name: Scalars['String'];
  stripe_information?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "coaches" */
export type CoachesStripe_InformationArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "coaches" */
export type Coaches_Aggregate = {
  __typename?: 'coaches_aggregate';
  aggregate?: Maybe<Coaches_Aggregate_Fields>;
  nodes: Array<Coaches>;
};

/** aggregate fields of "coaches" */
export type Coaches_Aggregate_Fields = {
  __typename?: 'coaches_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Coaches_Max_Fields>;
  min?: Maybe<Coaches_Min_Fields>;
};


/** aggregate fields of "coaches" */
export type Coaches_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Coaches_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coaches" */
export type Coaches_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Coaches_Max_Order_By>;
  min?: Maybe<Coaches_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Coaches_Append_Input = {
  stripe_information?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "coaches" */
export type Coaches_Arr_Rel_Insert_Input = {
  data: Array<Coaches_Insert_Input>;
  on_conflict?: Maybe<Coaches_On_Conflict>;
};

/** Boolean expression to filter rows from the table "coaches". All fields are combined with a logical 'AND'. */
export type Coaches_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Coaches_Bool_Exp>>>;
  _not?: Maybe<Coaches_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Coaches_Bool_Exp>>>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  stripe_information?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "coaches" */
export enum Coaches_Constraint {
  /** unique or primary key constraint */
  CoachesPkey = 'coaches_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Coaches_Delete_At_Path_Input = {
  stripe_information?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Coaches_Delete_Elem_Input = {
  stripe_information?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Coaches_Delete_Key_Input = {
  stripe_information?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "coaches" */
export type Coaches_Insert_Input = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  stripe_information?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Coaches_Max_Fields = {
  __typename?: 'coaches_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "coaches" */
export type Coaches_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Coaches_Min_Fields = {
  __typename?: 'coaches_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "coaches" */
export type Coaches_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "coaches" */
export type Coaches_Mutation_Response = {
  __typename?: 'coaches_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Coaches>;
};

/** input type for inserting object relation for remote table "coaches" */
export type Coaches_Obj_Rel_Insert_Input = {
  data: Coaches_Insert_Input;
  on_conflict?: Maybe<Coaches_On_Conflict>;
};

/** on conflict condition type for table "coaches" */
export type Coaches_On_Conflict = {
  constraint: Coaches_Constraint;
  update_columns: Array<Coaches_Update_Column>;
  where?: Maybe<Coaches_Bool_Exp>;
};

/** ordering options when selecting data from "coaches" */
export type Coaches_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  stripe_information?: Maybe<Order_By>;
};

/** primary key columns input for table: "coaches" */
export type Coaches_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Coaches_Prepend_Input = {
  stripe_information?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "coaches" */
export enum Coaches_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StripeInformation = 'stripe_information'
}

/** input type for updating data in table "coaches" */
export type Coaches_Set_Input = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  stripe_information?: Maybe<Scalars['jsonb']>;
};

/** update columns of table "coaches" */
export enum Coaches_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StripeInformation = 'stripe_information'
}

/** columns and relationships of "content_reports" */
export type Content_Reports = {
  __typename?: 'content_reports';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  message: Scalars['String'];
  problem_type: Scalars['String'];
  program_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** aggregated selection of "content_reports" */
export type Content_Reports_Aggregate = {
  __typename?: 'content_reports_aggregate';
  aggregate?: Maybe<Content_Reports_Aggregate_Fields>;
  nodes: Array<Content_Reports>;
};

/** aggregate fields of "content_reports" */
export type Content_Reports_Aggregate_Fields = {
  __typename?: 'content_reports_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Content_Reports_Max_Fields>;
  min?: Maybe<Content_Reports_Min_Fields>;
};


/** aggregate fields of "content_reports" */
export type Content_Reports_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Content_Reports_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "content_reports" */
export type Content_Reports_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Content_Reports_Max_Order_By>;
  min?: Maybe<Content_Reports_Min_Order_By>;
};

/** input type for inserting array relation for remote table "content_reports" */
export type Content_Reports_Arr_Rel_Insert_Input = {
  data: Array<Content_Reports_Insert_Input>;
  on_conflict?: Maybe<Content_Reports_On_Conflict>;
};

/** Boolean expression to filter rows from the table "content_reports". All fields are combined with a logical 'AND'. */
export type Content_Reports_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Content_Reports_Bool_Exp>>>;
  _not?: Maybe<Content_Reports_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Content_Reports_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  problem_type?: Maybe<String_Comparison_Exp>;
  program_id?: Maybe<Uuid_Comparison_Exp>;
  publication_id?: Maybe<Uuid_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "content_reports" */
export enum Content_Reports_Constraint {
  /** unique or primary key constraint */
  ContentReportsPkey = 'content_reports_pkey'
}

/** input type for inserting data into table "content_reports" */
export type Content_Reports_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  problem_type?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Content_Reports_Max_Fields = {
  __typename?: 'content_reports_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  problem_type?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "content_reports" */
export type Content_Reports_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  problem_type?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  publication_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Content_Reports_Min_Fields = {
  __typename?: 'content_reports_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  problem_type?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "content_reports" */
export type Content_Reports_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  problem_type?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  publication_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "content_reports" */
export type Content_Reports_Mutation_Response = {
  __typename?: 'content_reports_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Content_Reports>;
};

/** input type for inserting object relation for remote table "content_reports" */
export type Content_Reports_Obj_Rel_Insert_Input = {
  data: Content_Reports_Insert_Input;
  on_conflict?: Maybe<Content_Reports_On_Conflict>;
};

/** on conflict condition type for table "content_reports" */
export type Content_Reports_On_Conflict = {
  constraint: Content_Reports_Constraint;
  update_columns: Array<Content_Reports_Update_Column>;
  where?: Maybe<Content_Reports_Bool_Exp>;
};

/** ordering options when selecting data from "content_reports" */
export type Content_Reports_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  problem_type?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  publication_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "content_reports" */
export type Content_Reports_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "content_reports" */
export enum Content_Reports_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ProblemType = 'problem_type',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "content_reports" */
export type Content_Reports_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  problem_type?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "content_reports" */
export enum Content_Reports_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ProblemType = 'problem_type',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "create_free_checkout_sessions" */
export type Create_Free_Checkout_Sessions = {
  __typename?: 'create_free_checkout_sessions';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  over: Scalars['Boolean'];
  /** An object relationship */
  program: Programs;
  program_id: Scalars['uuid'];
  status: Scalars['String'];
  user_id: Scalars['String'];
};

/** aggregated selection of "create_free_checkout_sessions" */
export type Create_Free_Checkout_Sessions_Aggregate = {
  __typename?: 'create_free_checkout_sessions_aggregate';
  aggregate?: Maybe<Create_Free_Checkout_Sessions_Aggregate_Fields>;
  nodes: Array<Create_Free_Checkout_Sessions>;
};

/** aggregate fields of "create_free_checkout_sessions" */
export type Create_Free_Checkout_Sessions_Aggregate_Fields = {
  __typename?: 'create_free_checkout_sessions_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Create_Free_Checkout_Sessions_Max_Fields>;
  min?: Maybe<Create_Free_Checkout_Sessions_Min_Fields>;
};


/** aggregate fields of "create_free_checkout_sessions" */
export type Create_Free_Checkout_Sessions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Create_Free_Checkout_Sessions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "create_free_checkout_sessions" */
export type Create_Free_Checkout_Sessions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Create_Free_Checkout_Sessions_Max_Order_By>;
  min?: Maybe<Create_Free_Checkout_Sessions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "create_free_checkout_sessions" */
export type Create_Free_Checkout_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Create_Free_Checkout_Sessions_Insert_Input>;
  on_conflict?: Maybe<Create_Free_Checkout_Sessions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "create_free_checkout_sessions". All fields are combined with a logical 'AND'. */
export type Create_Free_Checkout_Sessions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Create_Free_Checkout_Sessions_Bool_Exp>>>;
  _not?: Maybe<Create_Free_Checkout_Sessions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Create_Free_Checkout_Sessions_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  over?: Maybe<Boolean_Comparison_Exp>;
  program?: Maybe<Programs_Bool_Exp>;
  program_id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "create_free_checkout_sessions" */
export enum Create_Free_Checkout_Sessions_Constraint {
  /** unique or primary key constraint */
  CreateFreeCheckoutSessionsPkey = 'create_free_checkout_sessions_pkey'
}

/** input type for inserting data into table "create_free_checkout_sessions" */
export type Create_Free_Checkout_Sessions_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  over?: Maybe<Scalars['Boolean']>;
  program?: Maybe<Programs_Obj_Rel_Insert_Input>;
  program_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Create_Free_Checkout_Sessions_Max_Fields = {
  __typename?: 'create_free_checkout_sessions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  program_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "create_free_checkout_sessions" */
export type Create_Free_Checkout_Sessions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Create_Free_Checkout_Sessions_Min_Fields = {
  __typename?: 'create_free_checkout_sessions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  program_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "create_free_checkout_sessions" */
export type Create_Free_Checkout_Sessions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "create_free_checkout_sessions" */
export type Create_Free_Checkout_Sessions_Mutation_Response = {
  __typename?: 'create_free_checkout_sessions_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Create_Free_Checkout_Sessions>;
};

/** input type for inserting object relation for remote table "create_free_checkout_sessions" */
export type Create_Free_Checkout_Sessions_Obj_Rel_Insert_Input = {
  data: Create_Free_Checkout_Sessions_Insert_Input;
  on_conflict?: Maybe<Create_Free_Checkout_Sessions_On_Conflict>;
};

/** on conflict condition type for table "create_free_checkout_sessions" */
export type Create_Free_Checkout_Sessions_On_Conflict = {
  constraint: Create_Free_Checkout_Sessions_Constraint;
  update_columns: Array<Create_Free_Checkout_Sessions_Update_Column>;
  where?: Maybe<Create_Free_Checkout_Sessions_Bool_Exp>;
};

/** ordering options when selecting data from "create_free_checkout_sessions" */
export type Create_Free_Checkout_Sessions_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  over?: Maybe<Order_By>;
  program?: Maybe<Programs_Order_By>;
  program_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "create_free_checkout_sessions" */
export type Create_Free_Checkout_Sessions_Pk_Columns_Input = {
  id: Scalars['uuid'];
  program_id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** select columns of table "create_free_checkout_sessions" */
export enum Create_Free_Checkout_Sessions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Over = 'over',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "create_free_checkout_sessions" */
export type Create_Free_Checkout_Sessions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  over?: Maybe<Scalars['Boolean']>;
  program_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** columns and relationships of "create_free_checkout_sessions_statuses" */
export type Create_Free_Checkout_Sessions_Statuses = {
  __typename?: 'create_free_checkout_sessions_statuses';
  status: Scalars['String'];
};

/** aggregated selection of "create_free_checkout_sessions_statuses" */
export type Create_Free_Checkout_Sessions_Statuses_Aggregate = {
  __typename?: 'create_free_checkout_sessions_statuses_aggregate';
  aggregate?: Maybe<Create_Free_Checkout_Sessions_Statuses_Aggregate_Fields>;
  nodes: Array<Create_Free_Checkout_Sessions_Statuses>;
};

/** aggregate fields of "create_free_checkout_sessions_statuses" */
export type Create_Free_Checkout_Sessions_Statuses_Aggregate_Fields = {
  __typename?: 'create_free_checkout_sessions_statuses_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Create_Free_Checkout_Sessions_Statuses_Max_Fields>;
  min?: Maybe<Create_Free_Checkout_Sessions_Statuses_Min_Fields>;
};


/** aggregate fields of "create_free_checkout_sessions_statuses" */
export type Create_Free_Checkout_Sessions_Statuses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Create_Free_Checkout_Sessions_Statuses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "create_free_checkout_sessions_statuses" */
export type Create_Free_Checkout_Sessions_Statuses_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Create_Free_Checkout_Sessions_Statuses_Max_Order_By>;
  min?: Maybe<Create_Free_Checkout_Sessions_Statuses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "create_free_checkout_sessions_statuses" */
export type Create_Free_Checkout_Sessions_Statuses_Arr_Rel_Insert_Input = {
  data: Array<Create_Free_Checkout_Sessions_Statuses_Insert_Input>;
  on_conflict?: Maybe<Create_Free_Checkout_Sessions_Statuses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "create_free_checkout_sessions_statuses". All fields are combined with a logical 'AND'. */
export type Create_Free_Checkout_Sessions_Statuses_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Create_Free_Checkout_Sessions_Statuses_Bool_Exp>>>;
  _not?: Maybe<Create_Free_Checkout_Sessions_Statuses_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Create_Free_Checkout_Sessions_Statuses_Bool_Exp>>>;
  status?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "create_free_checkout_sessions_statuses" */
export enum Create_Free_Checkout_Sessions_Statuses_Constraint {
  /** unique or primary key constraint */
  CreateFreeCheckoutSessionsStatusesPkey = 'create_free_checkout_sessions_statuses_pkey'
}

/** input type for inserting data into table "create_free_checkout_sessions_statuses" */
export type Create_Free_Checkout_Sessions_Statuses_Insert_Input = {
  status?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Create_Free_Checkout_Sessions_Statuses_Max_Fields = {
  __typename?: 'create_free_checkout_sessions_statuses_max_fields';
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "create_free_checkout_sessions_statuses" */
export type Create_Free_Checkout_Sessions_Statuses_Max_Order_By = {
  status?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Create_Free_Checkout_Sessions_Statuses_Min_Fields = {
  __typename?: 'create_free_checkout_sessions_statuses_min_fields';
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "create_free_checkout_sessions_statuses" */
export type Create_Free_Checkout_Sessions_Statuses_Min_Order_By = {
  status?: Maybe<Order_By>;
};

/** response of any mutation on the table "create_free_checkout_sessions_statuses" */
export type Create_Free_Checkout_Sessions_Statuses_Mutation_Response = {
  __typename?: 'create_free_checkout_sessions_statuses_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Create_Free_Checkout_Sessions_Statuses>;
};

/** input type for inserting object relation for remote table "create_free_checkout_sessions_statuses" */
export type Create_Free_Checkout_Sessions_Statuses_Obj_Rel_Insert_Input = {
  data: Create_Free_Checkout_Sessions_Statuses_Insert_Input;
  on_conflict?: Maybe<Create_Free_Checkout_Sessions_Statuses_On_Conflict>;
};

/** on conflict condition type for table "create_free_checkout_sessions_statuses" */
export type Create_Free_Checkout_Sessions_Statuses_On_Conflict = {
  constraint: Create_Free_Checkout_Sessions_Statuses_Constraint;
  update_columns: Array<Create_Free_Checkout_Sessions_Statuses_Update_Column>;
  where?: Maybe<Create_Free_Checkout_Sessions_Statuses_Bool_Exp>;
};

/** ordering options when selecting data from "create_free_checkout_sessions_statuses" */
export type Create_Free_Checkout_Sessions_Statuses_Order_By = {
  status?: Maybe<Order_By>;
};

/** primary key columns input for table: "create_free_checkout_sessions_statuses" */
export type Create_Free_Checkout_Sessions_Statuses_Pk_Columns_Input = {
  status: Scalars['String'];
};

/** select columns of table "create_free_checkout_sessions_statuses" */
export enum Create_Free_Checkout_Sessions_Statuses_Select_Column {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "create_free_checkout_sessions_statuses" */
export type Create_Free_Checkout_Sessions_Statuses_Set_Input = {
  status?: Maybe<Scalars['String']>;
};

/** update columns of table "create_free_checkout_sessions_statuses" */
export enum Create_Free_Checkout_Sessions_Statuses_Update_Column {
  /** column name */
  Status = 'status'
}

/** update columns of table "create_free_checkout_sessions" */
export enum Create_Free_Checkout_Sessions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Over = 'over',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "create_stripe_checkout_session_statuses" */
export type Create_Stripe_Checkout_Session_Statuses = {
  __typename?: 'create_stripe_checkout_session_statuses';
  status: Scalars['String'];
};

/** aggregated selection of "create_stripe_checkout_session_statuses" */
export type Create_Stripe_Checkout_Session_Statuses_Aggregate = {
  __typename?: 'create_stripe_checkout_session_statuses_aggregate';
  aggregate?: Maybe<Create_Stripe_Checkout_Session_Statuses_Aggregate_Fields>;
  nodes: Array<Create_Stripe_Checkout_Session_Statuses>;
};

/** aggregate fields of "create_stripe_checkout_session_statuses" */
export type Create_Stripe_Checkout_Session_Statuses_Aggregate_Fields = {
  __typename?: 'create_stripe_checkout_session_statuses_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Create_Stripe_Checkout_Session_Statuses_Max_Fields>;
  min?: Maybe<Create_Stripe_Checkout_Session_Statuses_Min_Fields>;
};


/** aggregate fields of "create_stripe_checkout_session_statuses" */
export type Create_Stripe_Checkout_Session_Statuses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Create_Stripe_Checkout_Session_Statuses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "create_stripe_checkout_session_statuses" */
export type Create_Stripe_Checkout_Session_Statuses_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Create_Stripe_Checkout_Session_Statuses_Max_Order_By>;
  min?: Maybe<Create_Stripe_Checkout_Session_Statuses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "create_stripe_checkout_session_statuses" */
export type Create_Stripe_Checkout_Session_Statuses_Arr_Rel_Insert_Input = {
  data: Array<Create_Stripe_Checkout_Session_Statuses_Insert_Input>;
  on_conflict?: Maybe<Create_Stripe_Checkout_Session_Statuses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "create_stripe_checkout_session_statuses". All fields are combined with a logical 'AND'. */
export type Create_Stripe_Checkout_Session_Statuses_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Create_Stripe_Checkout_Session_Statuses_Bool_Exp>>>;
  _not?: Maybe<Create_Stripe_Checkout_Session_Statuses_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Create_Stripe_Checkout_Session_Statuses_Bool_Exp>>>;
  status?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "create_stripe_checkout_session_statuses" */
export enum Create_Stripe_Checkout_Session_Statuses_Constraint {
  /** unique or primary key constraint */
  CreateStripeCheckoutSessionStatusesPkey = 'create_stripe_checkout_session_statuses_pkey'
}

export enum Create_Stripe_Checkout_Session_Statuses_Enum {
  Done = 'DONE',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  UserHasAlreadyBoughtProgram = 'USER_HAS_ALREADY_BOUGHT_PROGRAM'
}

/** expression to compare columns of type create_stripe_checkout_session_statuses_enum. All fields are combined with logical 'AND'. */
export type Create_Stripe_Checkout_Session_Statuses_Enum_Comparison_Exp = {
  _eq?: Maybe<Create_Stripe_Checkout_Session_Statuses_Enum>;
  _in?: Maybe<Array<Create_Stripe_Checkout_Session_Statuses_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Create_Stripe_Checkout_Session_Statuses_Enum>;
  _nin?: Maybe<Array<Create_Stripe_Checkout_Session_Statuses_Enum>>;
};

/** input type for inserting data into table "create_stripe_checkout_session_statuses" */
export type Create_Stripe_Checkout_Session_Statuses_Insert_Input = {
  status?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Create_Stripe_Checkout_Session_Statuses_Max_Fields = {
  __typename?: 'create_stripe_checkout_session_statuses_max_fields';
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "create_stripe_checkout_session_statuses" */
export type Create_Stripe_Checkout_Session_Statuses_Max_Order_By = {
  status?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Create_Stripe_Checkout_Session_Statuses_Min_Fields = {
  __typename?: 'create_stripe_checkout_session_statuses_min_fields';
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "create_stripe_checkout_session_statuses" */
export type Create_Stripe_Checkout_Session_Statuses_Min_Order_By = {
  status?: Maybe<Order_By>;
};

/** response of any mutation on the table "create_stripe_checkout_session_statuses" */
export type Create_Stripe_Checkout_Session_Statuses_Mutation_Response = {
  __typename?: 'create_stripe_checkout_session_statuses_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Create_Stripe_Checkout_Session_Statuses>;
};

/** input type for inserting object relation for remote table "create_stripe_checkout_session_statuses" */
export type Create_Stripe_Checkout_Session_Statuses_Obj_Rel_Insert_Input = {
  data: Create_Stripe_Checkout_Session_Statuses_Insert_Input;
  on_conflict?: Maybe<Create_Stripe_Checkout_Session_Statuses_On_Conflict>;
};

/** on conflict condition type for table "create_stripe_checkout_session_statuses" */
export type Create_Stripe_Checkout_Session_Statuses_On_Conflict = {
  constraint: Create_Stripe_Checkout_Session_Statuses_Constraint;
  update_columns: Array<Create_Stripe_Checkout_Session_Statuses_Update_Column>;
  where?: Maybe<Create_Stripe_Checkout_Session_Statuses_Bool_Exp>;
};

/** ordering options when selecting data from "create_stripe_checkout_session_statuses" */
export type Create_Stripe_Checkout_Session_Statuses_Order_By = {
  status?: Maybe<Order_By>;
};

/** primary key columns input for table: "create_stripe_checkout_session_statuses" */
export type Create_Stripe_Checkout_Session_Statuses_Pk_Columns_Input = {
  status: Scalars['String'];
};

/** select columns of table "create_stripe_checkout_session_statuses" */
export enum Create_Stripe_Checkout_Session_Statuses_Select_Column {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "create_stripe_checkout_session_statuses" */
export type Create_Stripe_Checkout_Session_Statuses_Set_Input = {
  status?: Maybe<Scalars['String']>;
};

/** update columns of table "create_stripe_checkout_session_statuses" */
export enum Create_Stripe_Checkout_Session_Statuses_Update_Column {
  /** column name */
  Status = 'status'
}

/** columns and relationships of "create_stripe_checkout_sessions" */
export type Create_Stripe_Checkout_Sessions = {
  __typename?: 'create_stripe_checkout_sessions';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  program_id: Scalars['uuid'];
  status: Create_Stripe_Checkout_Session_Statuses_Enum;
  user_id: Scalars['String'];
};

/** aggregated selection of "create_stripe_checkout_sessions" */
export type Create_Stripe_Checkout_Sessions_Aggregate = {
  __typename?: 'create_stripe_checkout_sessions_aggregate';
  aggregate?: Maybe<Create_Stripe_Checkout_Sessions_Aggregate_Fields>;
  nodes: Array<Create_Stripe_Checkout_Sessions>;
};

/** aggregate fields of "create_stripe_checkout_sessions" */
export type Create_Stripe_Checkout_Sessions_Aggregate_Fields = {
  __typename?: 'create_stripe_checkout_sessions_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Create_Stripe_Checkout_Sessions_Max_Fields>;
  min?: Maybe<Create_Stripe_Checkout_Sessions_Min_Fields>;
};


/** aggregate fields of "create_stripe_checkout_sessions" */
export type Create_Stripe_Checkout_Sessions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Create_Stripe_Checkout_Sessions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "create_stripe_checkout_sessions" */
export type Create_Stripe_Checkout_Sessions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Create_Stripe_Checkout_Sessions_Max_Order_By>;
  min?: Maybe<Create_Stripe_Checkout_Sessions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "create_stripe_checkout_sessions" */
export type Create_Stripe_Checkout_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Create_Stripe_Checkout_Sessions_Insert_Input>;
  on_conflict?: Maybe<Create_Stripe_Checkout_Sessions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "create_stripe_checkout_sessions". All fields are combined with a logical 'AND'. */
export type Create_Stripe_Checkout_Sessions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Create_Stripe_Checkout_Sessions_Bool_Exp>>>;
  _not?: Maybe<Create_Stripe_Checkout_Sessions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Create_Stripe_Checkout_Sessions_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  program_id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<Create_Stripe_Checkout_Session_Statuses_Enum_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "create_stripe_checkout_sessions" */
export enum Create_Stripe_Checkout_Sessions_Constraint {
  /** unique or primary key constraint */
  CreateStripeCheckoutSessionsIdKey = 'create_stripe_checkout_sessions_id_key',
  /** unique or primary key constraint */
  CreateStripeCheckoutSessionsPkey = 'create_stripe_checkout_sessions_pkey'
}

/** input type for inserting data into table "create_stripe_checkout_sessions" */
export type Create_Stripe_Checkout_Sessions_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  program_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Create_Stripe_Checkout_Session_Statuses_Enum>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Create_Stripe_Checkout_Sessions_Max_Fields = {
  __typename?: 'create_stripe_checkout_sessions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  program_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "create_stripe_checkout_sessions" */
export type Create_Stripe_Checkout_Sessions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Create_Stripe_Checkout_Sessions_Min_Fields = {
  __typename?: 'create_stripe_checkout_sessions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  program_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "create_stripe_checkout_sessions" */
export type Create_Stripe_Checkout_Sessions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "create_stripe_checkout_sessions" */
export type Create_Stripe_Checkout_Sessions_Mutation_Response = {
  __typename?: 'create_stripe_checkout_sessions_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Create_Stripe_Checkout_Sessions>;
};

/** input type for inserting object relation for remote table "create_stripe_checkout_sessions" */
export type Create_Stripe_Checkout_Sessions_Obj_Rel_Insert_Input = {
  data: Create_Stripe_Checkout_Sessions_Insert_Input;
  on_conflict?: Maybe<Create_Stripe_Checkout_Sessions_On_Conflict>;
};

/** on conflict condition type for table "create_stripe_checkout_sessions" */
export type Create_Stripe_Checkout_Sessions_On_Conflict = {
  constraint: Create_Stripe_Checkout_Sessions_Constraint;
  update_columns: Array<Create_Stripe_Checkout_Sessions_Update_Column>;
  where?: Maybe<Create_Stripe_Checkout_Sessions_Bool_Exp>;
};

/** ordering options when selecting data from "create_stripe_checkout_sessions" */
export type Create_Stripe_Checkout_Sessions_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "create_stripe_checkout_sessions" */
export type Create_Stripe_Checkout_Sessions_Pk_Columns_Input = {
  created_at: Scalars['timestamptz'];
  program_id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** select columns of table "create_stripe_checkout_sessions" */
export enum Create_Stripe_Checkout_Sessions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "create_stripe_checkout_sessions" */
export type Create_Stripe_Checkout_Sessions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  program_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Create_Stripe_Checkout_Session_Statuses_Enum>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "create_stripe_checkout_sessions" */
export enum Create_Stripe_Checkout_Sessions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "default_available_programs" */
export type Default_Available_Programs = {
  __typename?: 'default_available_programs';
  id: Scalars['uuid'];
  language: Scalars['String'];
  /** An object relationship */
  program: Programs;
};

/** aggregated selection of "default_available_programs" */
export type Default_Available_Programs_Aggregate = {
  __typename?: 'default_available_programs_aggregate';
  aggregate?: Maybe<Default_Available_Programs_Aggregate_Fields>;
  nodes: Array<Default_Available_Programs>;
};

/** aggregate fields of "default_available_programs" */
export type Default_Available_Programs_Aggregate_Fields = {
  __typename?: 'default_available_programs_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Default_Available_Programs_Max_Fields>;
  min?: Maybe<Default_Available_Programs_Min_Fields>;
};


/** aggregate fields of "default_available_programs" */
export type Default_Available_Programs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Default_Available_Programs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "default_available_programs" */
export type Default_Available_Programs_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Default_Available_Programs_Max_Order_By>;
  min?: Maybe<Default_Available_Programs_Min_Order_By>;
};

/** input type for inserting array relation for remote table "default_available_programs" */
export type Default_Available_Programs_Arr_Rel_Insert_Input = {
  data: Array<Default_Available_Programs_Insert_Input>;
  on_conflict?: Maybe<Default_Available_Programs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "default_available_programs". All fields are combined with a logical 'AND'. */
export type Default_Available_Programs_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Default_Available_Programs_Bool_Exp>>>;
  _not?: Maybe<Default_Available_Programs_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Default_Available_Programs_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  program?: Maybe<Programs_Bool_Exp>;
};

/** unique or primary key constraints on table "default_available_programs" */
export enum Default_Available_Programs_Constraint {
  /** unique or primary key constraint */
  DefaultAvailableProgramsPkey = 'default_available_programs_pkey'
}

/** input type for inserting data into table "default_available_programs" */
export type Default_Available_Programs_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  program?: Maybe<Programs_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Default_Available_Programs_Max_Fields = {
  __typename?: 'default_available_programs_max_fields';
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "default_available_programs" */
export type Default_Available_Programs_Max_Order_By = {
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Default_Available_Programs_Min_Fields = {
  __typename?: 'default_available_programs_min_fields';
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "default_available_programs" */
export type Default_Available_Programs_Min_Order_By = {
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
};

/** response of any mutation on the table "default_available_programs" */
export type Default_Available_Programs_Mutation_Response = {
  __typename?: 'default_available_programs_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Default_Available_Programs>;
};

/** input type for inserting object relation for remote table "default_available_programs" */
export type Default_Available_Programs_Obj_Rel_Insert_Input = {
  data: Default_Available_Programs_Insert_Input;
  on_conflict?: Maybe<Default_Available_Programs_On_Conflict>;
};

/** on conflict condition type for table "default_available_programs" */
export type Default_Available_Programs_On_Conflict = {
  constraint: Default_Available_Programs_Constraint;
  update_columns: Array<Default_Available_Programs_Update_Column>;
  where?: Maybe<Default_Available_Programs_Bool_Exp>;
};

/** ordering options when selecting data from "default_available_programs" */
export type Default_Available_Programs_Order_By = {
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  program?: Maybe<Programs_Order_By>;
};

/** primary key columns input for table: "default_available_programs" */
export type Default_Available_Programs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "default_available_programs" */
export enum Default_Available_Programs_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language'
}

/** input type for updating data in table "default_available_programs" */
export type Default_Available_Programs_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
};

/** update columns of table "default_available_programs" */
export enum Default_Available_Programs_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language'
}


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "coaches" */
  delete_coaches?: Maybe<Coaches_Mutation_Response>;
  /** delete single row from the table: "coaches" */
  delete_coaches_by_pk?: Maybe<Coaches>;
  /** delete data from the table: "content_reports" */
  delete_content_reports?: Maybe<Content_Reports_Mutation_Response>;
  /** delete single row from the table: "content_reports" */
  delete_content_reports_by_pk?: Maybe<Content_Reports>;
  /** delete data from the table: "create_free_checkout_sessions" */
  delete_create_free_checkout_sessions?: Maybe<Create_Free_Checkout_Sessions_Mutation_Response>;
  /** delete single row from the table: "create_free_checkout_sessions" */
  delete_create_free_checkout_sessions_by_pk?: Maybe<Create_Free_Checkout_Sessions>;
  /** delete data from the table: "create_free_checkout_sessions_statuses" */
  delete_create_free_checkout_sessions_statuses?: Maybe<Create_Free_Checkout_Sessions_Statuses_Mutation_Response>;
  /** delete single row from the table: "create_free_checkout_sessions_statuses" */
  delete_create_free_checkout_sessions_statuses_by_pk?: Maybe<Create_Free_Checkout_Sessions_Statuses>;
  /** delete data from the table: "create_stripe_checkout_session_statuses" */
  delete_create_stripe_checkout_session_statuses?: Maybe<Create_Stripe_Checkout_Session_Statuses_Mutation_Response>;
  /** delete single row from the table: "create_stripe_checkout_session_statuses" */
  delete_create_stripe_checkout_session_statuses_by_pk?: Maybe<Create_Stripe_Checkout_Session_Statuses>;
  /** delete data from the table: "create_stripe_checkout_sessions" */
  delete_create_stripe_checkout_sessions?: Maybe<Create_Stripe_Checkout_Sessions_Mutation_Response>;
  /** delete single row from the table: "create_stripe_checkout_sessions" */
  delete_create_stripe_checkout_sessions_by_pk?: Maybe<Create_Stripe_Checkout_Sessions>;
  /** delete data from the table: "default_available_programs" */
  delete_default_available_programs?: Maybe<Default_Available_Programs_Mutation_Response>;
  /** delete single row from the table: "default_available_programs" */
  delete_default_available_programs_by_pk?: Maybe<Default_Available_Programs>;
  /** delete data from the table: "program_public_pages" */
  delete_program_public_pages?: Maybe<Program_Public_Pages_Mutation_Response>;
  /** delete single row from the table: "program_public_pages" */
  delete_program_public_pages_by_pk?: Maybe<Program_Public_Pages>;
  /** delete data from the table: "program_publications" */
  delete_program_publications?: Maybe<Program_Publications_Mutation_Response>;
  /** delete single row from the table: "program_publications" */
  delete_program_publications_by_pk?: Maybe<Program_Publications>;
  /** delete data from the table: "program_routines" */
  delete_program_routines?: Maybe<Program_Routines_Mutation_Response>;
  /** delete single row from the table: "program_routines" */
  delete_program_routines_by_pk?: Maybe<Program_Routines>;
  /** delete data from the table: "programs" */
  delete_programs?: Maybe<Programs_Mutation_Response>;
  /** delete single row from the table: "programs" */
  delete_programs_by_pk?: Maybe<Programs>;
  /** delete data from the table: "stripe_checkout_session_statuses" */
  delete_stripe_checkout_session_statuses?: Maybe<Stripe_Checkout_Session_Statuses_Mutation_Response>;
  /** delete single row from the table: "stripe_checkout_session_statuses" */
  delete_stripe_checkout_session_statuses_by_pk?: Maybe<Stripe_Checkout_Session_Statuses>;
  /** delete data from the table: "stripe_checkout_sessions" */
  delete_stripe_checkout_sessions?: Maybe<Stripe_Checkout_Sessions_Mutation_Response>;
  /** delete single row from the table: "stripe_checkout_sessions" */
  delete_stripe_checkout_sessions_by_pk?: Maybe<Stripe_Checkout_Sessions>;
  /** delete data from the table: "user_readiness" */
  delete_user_readiness?: Maybe<User_Readiness_Mutation_Response>;
  /** delete single row from the table: "user_readiness" */
  delete_user_readiness_by_pk?: Maybe<User_Readiness>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "users_to_programs" */
  delete_users_to_programs?: Maybe<Users_To_Programs_Mutation_Response>;
  /** delete single row from the table: "users_to_programs" */
  delete_users_to_programs_by_pk?: Maybe<Users_To_Programs>;
  /** insert data into the table: "coaches" */
  insert_coaches?: Maybe<Coaches_Mutation_Response>;
  /** insert a single row into the table: "coaches" */
  insert_coaches_one?: Maybe<Coaches>;
  /** insert data into the table: "content_reports" */
  insert_content_reports?: Maybe<Content_Reports_Mutation_Response>;
  /** insert a single row into the table: "content_reports" */
  insert_content_reports_one?: Maybe<Content_Reports>;
  /** insert data into the table: "create_free_checkout_sessions" */
  insert_create_free_checkout_sessions?: Maybe<Create_Free_Checkout_Sessions_Mutation_Response>;
  /** insert a single row into the table: "create_free_checkout_sessions" */
  insert_create_free_checkout_sessions_one?: Maybe<Create_Free_Checkout_Sessions>;
  /** insert data into the table: "create_free_checkout_sessions_statuses" */
  insert_create_free_checkout_sessions_statuses?: Maybe<Create_Free_Checkout_Sessions_Statuses_Mutation_Response>;
  /** insert a single row into the table: "create_free_checkout_sessions_statuses" */
  insert_create_free_checkout_sessions_statuses_one?: Maybe<Create_Free_Checkout_Sessions_Statuses>;
  /** insert data into the table: "create_stripe_checkout_session_statuses" */
  insert_create_stripe_checkout_session_statuses?: Maybe<Create_Stripe_Checkout_Session_Statuses_Mutation_Response>;
  /** insert a single row into the table: "create_stripe_checkout_session_statuses" */
  insert_create_stripe_checkout_session_statuses_one?: Maybe<Create_Stripe_Checkout_Session_Statuses>;
  /** insert data into the table: "create_stripe_checkout_sessions" */
  insert_create_stripe_checkout_sessions?: Maybe<Create_Stripe_Checkout_Sessions_Mutation_Response>;
  /** insert a single row into the table: "create_stripe_checkout_sessions" */
  insert_create_stripe_checkout_sessions_one?: Maybe<Create_Stripe_Checkout_Sessions>;
  /** insert data into the table: "default_available_programs" */
  insert_default_available_programs?: Maybe<Default_Available_Programs_Mutation_Response>;
  /** insert a single row into the table: "default_available_programs" */
  insert_default_available_programs_one?: Maybe<Default_Available_Programs>;
  /** insert data into the table: "program_public_pages" */
  insert_program_public_pages?: Maybe<Program_Public_Pages_Mutation_Response>;
  /** insert a single row into the table: "program_public_pages" */
  insert_program_public_pages_one?: Maybe<Program_Public_Pages>;
  /** insert data into the table: "program_publications" */
  insert_program_publications?: Maybe<Program_Publications_Mutation_Response>;
  /** insert a single row into the table: "program_publications" */
  insert_program_publications_one?: Maybe<Program_Publications>;
  /** insert data into the table: "program_routines" */
  insert_program_routines?: Maybe<Program_Routines_Mutation_Response>;
  /** insert a single row into the table: "program_routines" */
  insert_program_routines_one?: Maybe<Program_Routines>;
  /** insert data into the table: "programs" */
  insert_programs?: Maybe<Programs_Mutation_Response>;
  /** insert a single row into the table: "programs" */
  insert_programs_one?: Maybe<Programs>;
  /** insert data into the table: "stripe_checkout_session_statuses" */
  insert_stripe_checkout_session_statuses?: Maybe<Stripe_Checkout_Session_Statuses_Mutation_Response>;
  /** insert a single row into the table: "stripe_checkout_session_statuses" */
  insert_stripe_checkout_session_statuses_one?: Maybe<Stripe_Checkout_Session_Statuses>;
  /** insert data into the table: "stripe_checkout_sessions" */
  insert_stripe_checkout_sessions?: Maybe<Stripe_Checkout_Sessions_Mutation_Response>;
  /** insert a single row into the table: "stripe_checkout_sessions" */
  insert_stripe_checkout_sessions_one?: Maybe<Stripe_Checkout_Sessions>;
  /** insert data into the table: "user_readiness" */
  insert_user_readiness?: Maybe<User_Readiness_Mutation_Response>;
  /** insert a single row into the table: "user_readiness" */
  insert_user_readiness_one?: Maybe<User_Readiness>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "users_to_programs" */
  insert_users_to_programs?: Maybe<Users_To_Programs_Mutation_Response>;
  /** insert a single row into the table: "users_to_programs" */
  insert_users_to_programs_one?: Maybe<Users_To_Programs>;
  /** update data of the table: "coaches" */
  update_coaches?: Maybe<Coaches_Mutation_Response>;
  /** update single row of the table: "coaches" */
  update_coaches_by_pk?: Maybe<Coaches>;
  /** update data of the table: "content_reports" */
  update_content_reports?: Maybe<Content_Reports_Mutation_Response>;
  /** update single row of the table: "content_reports" */
  update_content_reports_by_pk?: Maybe<Content_Reports>;
  /** update data of the table: "create_free_checkout_sessions" */
  update_create_free_checkout_sessions?: Maybe<Create_Free_Checkout_Sessions_Mutation_Response>;
  /** update single row of the table: "create_free_checkout_sessions" */
  update_create_free_checkout_sessions_by_pk?: Maybe<Create_Free_Checkout_Sessions>;
  /** update data of the table: "create_free_checkout_sessions_statuses" */
  update_create_free_checkout_sessions_statuses?: Maybe<Create_Free_Checkout_Sessions_Statuses_Mutation_Response>;
  /** update single row of the table: "create_free_checkout_sessions_statuses" */
  update_create_free_checkout_sessions_statuses_by_pk?: Maybe<Create_Free_Checkout_Sessions_Statuses>;
  /** update data of the table: "create_stripe_checkout_session_statuses" */
  update_create_stripe_checkout_session_statuses?: Maybe<Create_Stripe_Checkout_Session_Statuses_Mutation_Response>;
  /** update single row of the table: "create_stripe_checkout_session_statuses" */
  update_create_stripe_checkout_session_statuses_by_pk?: Maybe<Create_Stripe_Checkout_Session_Statuses>;
  /** update data of the table: "create_stripe_checkout_sessions" */
  update_create_stripe_checkout_sessions?: Maybe<Create_Stripe_Checkout_Sessions_Mutation_Response>;
  /** update single row of the table: "create_stripe_checkout_sessions" */
  update_create_stripe_checkout_sessions_by_pk?: Maybe<Create_Stripe_Checkout_Sessions>;
  /** update data of the table: "default_available_programs" */
  update_default_available_programs?: Maybe<Default_Available_Programs_Mutation_Response>;
  /** update single row of the table: "default_available_programs" */
  update_default_available_programs_by_pk?: Maybe<Default_Available_Programs>;
  /** update data of the table: "program_public_pages" */
  update_program_public_pages?: Maybe<Program_Public_Pages_Mutation_Response>;
  /** update single row of the table: "program_public_pages" */
  update_program_public_pages_by_pk?: Maybe<Program_Public_Pages>;
  /** update data of the table: "program_publications" */
  update_program_publications?: Maybe<Program_Publications_Mutation_Response>;
  /** update single row of the table: "program_publications" */
  update_program_publications_by_pk?: Maybe<Program_Publications>;
  /** update data of the table: "program_routines" */
  update_program_routines?: Maybe<Program_Routines_Mutation_Response>;
  /** update single row of the table: "program_routines" */
  update_program_routines_by_pk?: Maybe<Program_Routines>;
  /** update data of the table: "programs" */
  update_programs?: Maybe<Programs_Mutation_Response>;
  /** update single row of the table: "programs" */
  update_programs_by_pk?: Maybe<Programs>;
  /** update data of the table: "stripe_checkout_session_statuses" */
  update_stripe_checkout_session_statuses?: Maybe<Stripe_Checkout_Session_Statuses_Mutation_Response>;
  /** update single row of the table: "stripe_checkout_session_statuses" */
  update_stripe_checkout_session_statuses_by_pk?: Maybe<Stripe_Checkout_Session_Statuses>;
  /** update data of the table: "stripe_checkout_sessions" */
  update_stripe_checkout_sessions?: Maybe<Stripe_Checkout_Sessions_Mutation_Response>;
  /** update single row of the table: "stripe_checkout_sessions" */
  update_stripe_checkout_sessions_by_pk?: Maybe<Stripe_Checkout_Sessions>;
  /** update data of the table: "user_readiness" */
  update_user_readiness?: Maybe<User_Readiness_Mutation_Response>;
  /** update single row of the table: "user_readiness" */
  update_user_readiness_by_pk?: Maybe<User_Readiness>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "users_to_programs" */
  update_users_to_programs?: Maybe<Users_To_Programs_Mutation_Response>;
  /** update single row of the table: "users_to_programs" */
  update_users_to_programs_by_pk?: Maybe<Users_To_Programs>;
};


/** mutation root */
export type Mutation_RootDelete_CoachesArgs = {
  where: Coaches_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Coaches_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Content_ReportsArgs = {
  where: Content_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Content_Reports_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Create_Free_Checkout_SessionsArgs = {
  where: Create_Free_Checkout_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Create_Free_Checkout_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
  program_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Create_Free_Checkout_Sessions_StatusesArgs = {
  where: Create_Free_Checkout_Sessions_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Create_Free_Checkout_Sessions_Statuses_By_PkArgs = {
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Create_Stripe_Checkout_Session_StatusesArgs = {
  where: Create_Stripe_Checkout_Session_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Create_Stripe_Checkout_Session_Statuses_By_PkArgs = {
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Create_Stripe_Checkout_SessionsArgs = {
  where: Create_Stripe_Checkout_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Create_Stripe_Checkout_Sessions_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  program_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Default_Available_ProgramsArgs = {
  where: Default_Available_Programs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Default_Available_Programs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Program_Public_PagesArgs = {
  where: Program_Public_Pages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Program_Public_Pages_By_PkArgs = {
  program_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Program_PublicationsArgs = {
  where: Program_Publications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Program_Publications_By_PkArgs = {
  publication_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Program_RoutinesArgs = {
  where: Program_Routines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Program_Routines_By_PkArgs = {
  publication_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProgramsArgs = {
  where: Programs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Programs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Checkout_Session_StatusesArgs = {
  where: Stripe_Checkout_Session_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Checkout_Session_Statuses_By_PkArgs = {
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Checkout_SessionsArgs = {
  where: Stripe_Checkout_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Checkout_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_ReadinessArgs = {
  where: User_Readiness_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Readiness_By_PkArgs = {
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Users_To_ProgramsArgs = {
  where: Users_To_Programs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_To_Programs_By_PkArgs = {
  program_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_CoachesArgs = {
  objects: Array<Coaches_Insert_Input>;
  on_conflict?: Maybe<Coaches_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coaches_OneArgs = {
  object: Coaches_Insert_Input;
  on_conflict?: Maybe<Coaches_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_ReportsArgs = {
  objects: Array<Content_Reports_Insert_Input>;
  on_conflict?: Maybe<Content_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Reports_OneArgs = {
  object: Content_Reports_Insert_Input;
  on_conflict?: Maybe<Content_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Create_Free_Checkout_SessionsArgs = {
  objects: Array<Create_Free_Checkout_Sessions_Insert_Input>;
  on_conflict?: Maybe<Create_Free_Checkout_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Create_Free_Checkout_Sessions_OneArgs = {
  object: Create_Free_Checkout_Sessions_Insert_Input;
  on_conflict?: Maybe<Create_Free_Checkout_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Create_Free_Checkout_Sessions_StatusesArgs = {
  objects: Array<Create_Free_Checkout_Sessions_Statuses_Insert_Input>;
  on_conflict?: Maybe<Create_Free_Checkout_Sessions_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Create_Free_Checkout_Sessions_Statuses_OneArgs = {
  object: Create_Free_Checkout_Sessions_Statuses_Insert_Input;
  on_conflict?: Maybe<Create_Free_Checkout_Sessions_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Create_Stripe_Checkout_Session_StatusesArgs = {
  objects: Array<Create_Stripe_Checkout_Session_Statuses_Insert_Input>;
  on_conflict?: Maybe<Create_Stripe_Checkout_Session_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Create_Stripe_Checkout_Session_Statuses_OneArgs = {
  object: Create_Stripe_Checkout_Session_Statuses_Insert_Input;
  on_conflict?: Maybe<Create_Stripe_Checkout_Session_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Create_Stripe_Checkout_SessionsArgs = {
  objects: Array<Create_Stripe_Checkout_Sessions_Insert_Input>;
  on_conflict?: Maybe<Create_Stripe_Checkout_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Create_Stripe_Checkout_Sessions_OneArgs = {
  object: Create_Stripe_Checkout_Sessions_Insert_Input;
  on_conflict?: Maybe<Create_Stripe_Checkout_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Default_Available_ProgramsArgs = {
  objects: Array<Default_Available_Programs_Insert_Input>;
  on_conflict?: Maybe<Default_Available_Programs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Default_Available_Programs_OneArgs = {
  object: Default_Available_Programs_Insert_Input;
  on_conflict?: Maybe<Default_Available_Programs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Program_Public_PagesArgs = {
  objects: Array<Program_Public_Pages_Insert_Input>;
  on_conflict?: Maybe<Program_Public_Pages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Program_Public_Pages_OneArgs = {
  object: Program_Public_Pages_Insert_Input;
  on_conflict?: Maybe<Program_Public_Pages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Program_PublicationsArgs = {
  objects: Array<Program_Publications_Insert_Input>;
  on_conflict?: Maybe<Program_Publications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Program_Publications_OneArgs = {
  object: Program_Publications_Insert_Input;
  on_conflict?: Maybe<Program_Publications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Program_RoutinesArgs = {
  objects: Array<Program_Routines_Insert_Input>;
  on_conflict?: Maybe<Program_Routines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Program_Routines_OneArgs = {
  object: Program_Routines_Insert_Input;
  on_conflict?: Maybe<Program_Routines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProgramsArgs = {
  objects: Array<Programs_Insert_Input>;
  on_conflict?: Maybe<Programs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Programs_OneArgs = {
  object: Programs_Insert_Input;
  on_conflict?: Maybe<Programs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Checkout_Session_StatusesArgs = {
  objects: Array<Stripe_Checkout_Session_Statuses_Insert_Input>;
  on_conflict?: Maybe<Stripe_Checkout_Session_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Checkout_Session_Statuses_OneArgs = {
  object: Stripe_Checkout_Session_Statuses_Insert_Input;
  on_conflict?: Maybe<Stripe_Checkout_Session_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Checkout_SessionsArgs = {
  objects: Array<Stripe_Checkout_Sessions_Insert_Input>;
  on_conflict?: Maybe<Stripe_Checkout_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Checkout_Sessions_OneArgs = {
  object: Stripe_Checkout_Sessions_Insert_Input;
  on_conflict?: Maybe<Stripe_Checkout_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_ReadinessArgs = {
  objects: Array<User_Readiness_Insert_Input>;
  on_conflict?: Maybe<User_Readiness_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Readiness_OneArgs = {
  object: User_Readiness_Insert_Input;
  on_conflict?: Maybe<User_Readiness_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_To_ProgramsArgs = {
  objects: Array<Users_To_Programs_Insert_Input>;
  on_conflict?: Maybe<Users_To_Programs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_To_Programs_OneArgs = {
  object: Users_To_Programs_Insert_Input;
  on_conflict?: Maybe<Users_To_Programs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_CoachesArgs = {
  _append?: Maybe<Coaches_Append_Input>;
  _delete_at_path?: Maybe<Coaches_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Coaches_Delete_Elem_Input>;
  _delete_key?: Maybe<Coaches_Delete_Key_Input>;
  _prepend?: Maybe<Coaches_Prepend_Input>;
  _set?: Maybe<Coaches_Set_Input>;
  where: Coaches_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Coaches_By_PkArgs = {
  _append?: Maybe<Coaches_Append_Input>;
  _delete_at_path?: Maybe<Coaches_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Coaches_Delete_Elem_Input>;
  _delete_key?: Maybe<Coaches_Delete_Key_Input>;
  _prepend?: Maybe<Coaches_Prepend_Input>;
  _set?: Maybe<Coaches_Set_Input>;
  pk_columns: Coaches_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Content_ReportsArgs = {
  _set?: Maybe<Content_Reports_Set_Input>;
  where: Content_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Reports_By_PkArgs = {
  _set?: Maybe<Content_Reports_Set_Input>;
  pk_columns: Content_Reports_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Create_Free_Checkout_SessionsArgs = {
  _set?: Maybe<Create_Free_Checkout_Sessions_Set_Input>;
  where: Create_Free_Checkout_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Create_Free_Checkout_Sessions_By_PkArgs = {
  _set?: Maybe<Create_Free_Checkout_Sessions_Set_Input>;
  pk_columns: Create_Free_Checkout_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Create_Free_Checkout_Sessions_StatusesArgs = {
  _set?: Maybe<Create_Free_Checkout_Sessions_Statuses_Set_Input>;
  where: Create_Free_Checkout_Sessions_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Create_Free_Checkout_Sessions_Statuses_By_PkArgs = {
  _set?: Maybe<Create_Free_Checkout_Sessions_Statuses_Set_Input>;
  pk_columns: Create_Free_Checkout_Sessions_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Create_Stripe_Checkout_Session_StatusesArgs = {
  _set?: Maybe<Create_Stripe_Checkout_Session_Statuses_Set_Input>;
  where: Create_Stripe_Checkout_Session_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Create_Stripe_Checkout_Session_Statuses_By_PkArgs = {
  _set?: Maybe<Create_Stripe_Checkout_Session_Statuses_Set_Input>;
  pk_columns: Create_Stripe_Checkout_Session_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Create_Stripe_Checkout_SessionsArgs = {
  _set?: Maybe<Create_Stripe_Checkout_Sessions_Set_Input>;
  where: Create_Stripe_Checkout_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Create_Stripe_Checkout_Sessions_By_PkArgs = {
  _set?: Maybe<Create_Stripe_Checkout_Sessions_Set_Input>;
  pk_columns: Create_Stripe_Checkout_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Default_Available_ProgramsArgs = {
  _set?: Maybe<Default_Available_Programs_Set_Input>;
  where: Default_Available_Programs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Default_Available_Programs_By_PkArgs = {
  _set?: Maybe<Default_Available_Programs_Set_Input>;
  pk_columns: Default_Available_Programs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Program_Public_PagesArgs = {
  _append?: Maybe<Program_Public_Pages_Append_Input>;
  _delete_at_path?: Maybe<Program_Public_Pages_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Program_Public_Pages_Delete_Elem_Input>;
  _delete_key?: Maybe<Program_Public_Pages_Delete_Key_Input>;
  _prepend?: Maybe<Program_Public_Pages_Prepend_Input>;
  _set?: Maybe<Program_Public_Pages_Set_Input>;
  where: Program_Public_Pages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Program_Public_Pages_By_PkArgs = {
  _append?: Maybe<Program_Public_Pages_Append_Input>;
  _delete_at_path?: Maybe<Program_Public_Pages_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Program_Public_Pages_Delete_Elem_Input>;
  _delete_key?: Maybe<Program_Public_Pages_Delete_Key_Input>;
  _prepend?: Maybe<Program_Public_Pages_Prepend_Input>;
  _set?: Maybe<Program_Public_Pages_Set_Input>;
  pk_columns: Program_Public_Pages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Program_PublicationsArgs = {
  _set?: Maybe<Program_Publications_Set_Input>;
  where: Program_Publications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Program_Publications_By_PkArgs = {
  _set?: Maybe<Program_Publications_Set_Input>;
  pk_columns: Program_Publications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Program_RoutinesArgs = {
  _append?: Maybe<Program_Routines_Append_Input>;
  _delete_at_path?: Maybe<Program_Routines_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Program_Routines_Delete_Elem_Input>;
  _delete_key?: Maybe<Program_Routines_Delete_Key_Input>;
  _prepend?: Maybe<Program_Routines_Prepend_Input>;
  _set?: Maybe<Program_Routines_Set_Input>;
  where: Program_Routines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Program_Routines_By_PkArgs = {
  _append?: Maybe<Program_Routines_Append_Input>;
  _delete_at_path?: Maybe<Program_Routines_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Program_Routines_Delete_Elem_Input>;
  _delete_key?: Maybe<Program_Routines_Delete_Key_Input>;
  _prepend?: Maybe<Program_Routines_Prepend_Input>;
  _set?: Maybe<Program_Routines_Set_Input>;
  pk_columns: Program_Routines_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProgramsArgs = {
  _inc?: Maybe<Programs_Inc_Input>;
  _set?: Maybe<Programs_Set_Input>;
  where: Programs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Programs_By_PkArgs = {
  _inc?: Maybe<Programs_Inc_Input>;
  _set?: Maybe<Programs_Set_Input>;
  pk_columns: Programs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Checkout_Session_StatusesArgs = {
  _set?: Maybe<Stripe_Checkout_Session_Statuses_Set_Input>;
  where: Stripe_Checkout_Session_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Checkout_Session_Statuses_By_PkArgs = {
  _set?: Maybe<Stripe_Checkout_Session_Statuses_Set_Input>;
  pk_columns: Stripe_Checkout_Session_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Checkout_SessionsArgs = {
  _set?: Maybe<Stripe_Checkout_Sessions_Set_Input>;
  where: Stripe_Checkout_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Checkout_Sessions_By_PkArgs = {
  _set?: Maybe<Stripe_Checkout_Sessions_Set_Input>;
  pk_columns: Stripe_Checkout_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ReadinessArgs = {
  _set?: Maybe<User_Readiness_Set_Input>;
  where: User_Readiness_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Readiness_By_PkArgs = {
  _set?: Maybe<User_Readiness_Set_Input>;
  pk_columns: User_Readiness_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_To_ProgramsArgs = {
  _set?: Maybe<Users_To_Programs_Set_Input>;
  where: Users_To_Programs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_To_Programs_By_PkArgs = {
  _set?: Maybe<Users_To_Programs_Set_Input>;
  pk_columns: Users_To_Programs_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "program_public_pages" */
export type Program_Public_Pages = {
  __typename?: 'program_public_pages';
  created_at: Scalars['timestamptz'];
  document: Scalars['jsonb'];
  program_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** columns and relationships of "program_public_pages" */
export type Program_Public_PagesDocumentArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "program_public_pages" */
export type Program_Public_Pages_Aggregate = {
  __typename?: 'program_public_pages_aggregate';
  aggregate?: Maybe<Program_Public_Pages_Aggregate_Fields>;
  nodes: Array<Program_Public_Pages>;
};

/** aggregate fields of "program_public_pages" */
export type Program_Public_Pages_Aggregate_Fields = {
  __typename?: 'program_public_pages_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Program_Public_Pages_Max_Fields>;
  min?: Maybe<Program_Public_Pages_Min_Fields>;
};


/** aggregate fields of "program_public_pages" */
export type Program_Public_Pages_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Program_Public_Pages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "program_public_pages" */
export type Program_Public_Pages_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Program_Public_Pages_Max_Order_By>;
  min?: Maybe<Program_Public_Pages_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Program_Public_Pages_Append_Input = {
  document?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "program_public_pages" */
export type Program_Public_Pages_Arr_Rel_Insert_Input = {
  data: Array<Program_Public_Pages_Insert_Input>;
  on_conflict?: Maybe<Program_Public_Pages_On_Conflict>;
};

/** Boolean expression to filter rows from the table "program_public_pages". All fields are combined with a logical 'AND'. */
export type Program_Public_Pages_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Program_Public_Pages_Bool_Exp>>>;
  _not?: Maybe<Program_Public_Pages_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Program_Public_Pages_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  document?: Maybe<Jsonb_Comparison_Exp>;
  program_id?: Maybe<Uuid_Comparison_Exp>;
  publication_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "program_public_pages" */
export enum Program_Public_Pages_Constraint {
  /** unique or primary key constraint */
  ProgramPublicPagesPkey = 'program_public_pages_pkey',
  /** unique or primary key constraint */
  ProgramPublicPagesPublicationIdKey = 'program_public_pages_publication_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Program_Public_Pages_Delete_At_Path_Input = {
  document?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Program_Public_Pages_Delete_Elem_Input = {
  document?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Program_Public_Pages_Delete_Key_Input = {
  document?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "program_public_pages" */
export type Program_Public_Pages_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  document?: Maybe<Scalars['jsonb']>;
  program_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Program_Public_Pages_Max_Fields = {
  __typename?: 'program_public_pages_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  program_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "program_public_pages" */
export type Program_Public_Pages_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  publication_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Program_Public_Pages_Min_Fields = {
  __typename?: 'program_public_pages_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  program_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "program_public_pages" */
export type Program_Public_Pages_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  publication_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "program_public_pages" */
export type Program_Public_Pages_Mutation_Response = {
  __typename?: 'program_public_pages_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Program_Public_Pages>;
};

/** input type for inserting object relation for remote table "program_public_pages" */
export type Program_Public_Pages_Obj_Rel_Insert_Input = {
  data: Program_Public_Pages_Insert_Input;
  on_conflict?: Maybe<Program_Public_Pages_On_Conflict>;
};

/** on conflict condition type for table "program_public_pages" */
export type Program_Public_Pages_On_Conflict = {
  constraint: Program_Public_Pages_Constraint;
  update_columns: Array<Program_Public_Pages_Update_Column>;
  where?: Maybe<Program_Public_Pages_Bool_Exp>;
};

/** ordering options when selecting data from "program_public_pages" */
export type Program_Public_Pages_Order_By = {
  created_at?: Maybe<Order_By>;
  document?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  publication_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "program_public_pages" */
export type Program_Public_Pages_Pk_Columns_Input = {
  program_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Program_Public_Pages_Prepend_Input = {
  document?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "program_public_pages" */
export enum Program_Public_Pages_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Document = 'document',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  PublicationId = 'publication_id'
}

/** input type for updating data in table "program_public_pages" */
export type Program_Public_Pages_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  document?: Maybe<Scalars['jsonb']>;
  program_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "program_public_pages" */
export enum Program_Public_Pages_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Document = 'document',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  PublicationId = 'publication_id'
}

/** columns and relationships of "program_publications" */
export type Program_Publications = {
  __typename?: 'program_publications';
  coach_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  program_id: Scalars['uuid'];
  /** An array relationship */
  program_public_pages: Array<Program_Public_Pages>;
  /** An aggregated array relationship */
  program_public_pages_aggregate: Program_Public_Pages_Aggregate;
  publication_id: Scalars['uuid'];
};


/** columns and relationships of "program_publications" */
export type Program_PublicationsProgram_Public_PagesArgs = {
  distinct_on?: Maybe<Array<Program_Public_Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Public_Pages_Order_By>>;
  where?: Maybe<Program_Public_Pages_Bool_Exp>;
};


/** columns and relationships of "program_publications" */
export type Program_PublicationsProgram_Public_Pages_AggregateArgs = {
  distinct_on?: Maybe<Array<Program_Public_Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Public_Pages_Order_By>>;
  where?: Maybe<Program_Public_Pages_Bool_Exp>;
};

/** aggregated selection of "program_publications" */
export type Program_Publications_Aggregate = {
  __typename?: 'program_publications_aggregate';
  aggregate?: Maybe<Program_Publications_Aggregate_Fields>;
  nodes: Array<Program_Publications>;
};

/** aggregate fields of "program_publications" */
export type Program_Publications_Aggregate_Fields = {
  __typename?: 'program_publications_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Program_Publications_Max_Fields>;
  min?: Maybe<Program_Publications_Min_Fields>;
};


/** aggregate fields of "program_publications" */
export type Program_Publications_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Program_Publications_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "program_publications" */
export type Program_Publications_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Program_Publications_Max_Order_By>;
  min?: Maybe<Program_Publications_Min_Order_By>;
};

/** input type for inserting array relation for remote table "program_publications" */
export type Program_Publications_Arr_Rel_Insert_Input = {
  data: Array<Program_Publications_Insert_Input>;
  on_conflict?: Maybe<Program_Publications_On_Conflict>;
};

/** Boolean expression to filter rows from the table "program_publications". All fields are combined with a logical 'AND'. */
export type Program_Publications_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Program_Publications_Bool_Exp>>>;
  _not?: Maybe<Program_Publications_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Program_Publications_Bool_Exp>>>;
  coach_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  program_id?: Maybe<Uuid_Comparison_Exp>;
  program_public_pages?: Maybe<Program_Public_Pages_Bool_Exp>;
  publication_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "program_publications" */
export enum Program_Publications_Constraint {
  /** unique or primary key constraint */
  ProgramPublicationsPkey = 'program_publications_pkey'
}

/** input type for inserting data into table "program_publications" */
export type Program_Publications_Insert_Input = {
  coach_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  program_id?: Maybe<Scalars['uuid']>;
  program_public_pages?: Maybe<Program_Public_Pages_Arr_Rel_Insert_Input>;
  publication_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Program_Publications_Max_Fields = {
  __typename?: 'program_publications_max_fields';
  coach_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  program_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "program_publications" */
export type Program_Publications_Max_Order_By = {
  coach_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  publication_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Program_Publications_Min_Fields = {
  __typename?: 'program_publications_min_fields';
  coach_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  program_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "program_publications" */
export type Program_Publications_Min_Order_By = {
  coach_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  publication_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "program_publications" */
export type Program_Publications_Mutation_Response = {
  __typename?: 'program_publications_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Program_Publications>;
};

/** input type for inserting object relation for remote table "program_publications" */
export type Program_Publications_Obj_Rel_Insert_Input = {
  data: Program_Publications_Insert_Input;
  on_conflict?: Maybe<Program_Publications_On_Conflict>;
};

/** on conflict condition type for table "program_publications" */
export type Program_Publications_On_Conflict = {
  constraint: Program_Publications_Constraint;
  update_columns: Array<Program_Publications_Update_Column>;
  where?: Maybe<Program_Publications_Bool_Exp>;
};

/** ordering options when selecting data from "program_publications" */
export type Program_Publications_Order_By = {
  coach_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  program_public_pages_aggregate?: Maybe<Program_Public_Pages_Aggregate_Order_By>;
  publication_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "program_publications" */
export type Program_Publications_Pk_Columns_Input = {
  publication_id: Scalars['uuid'];
};

/** select columns of table "program_publications" */
export enum Program_Publications_Select_Column {
  /** column name */
  CoachId = 'coach_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  PublicationId = 'publication_id'
}

/** input type for updating data in table "program_publications" */
export type Program_Publications_Set_Input = {
  coach_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  program_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "program_publications" */
export enum Program_Publications_Update_Column {
  /** column name */
  CoachId = 'coach_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  PublicationId = 'publication_id'
}

/** columns and relationships of "program_routines" */
export type Program_Routines = {
  __typename?: 'program_routines';
  created_at: Scalars['timestamptz'];
  document: Scalars['jsonb'];
  program_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** columns and relationships of "program_routines" */
export type Program_RoutinesDocumentArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "program_routines" */
export type Program_Routines_Aggregate = {
  __typename?: 'program_routines_aggregate';
  aggregate?: Maybe<Program_Routines_Aggregate_Fields>;
  nodes: Array<Program_Routines>;
};

/** aggregate fields of "program_routines" */
export type Program_Routines_Aggregate_Fields = {
  __typename?: 'program_routines_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Program_Routines_Max_Fields>;
  min?: Maybe<Program_Routines_Min_Fields>;
};


/** aggregate fields of "program_routines" */
export type Program_Routines_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Program_Routines_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "program_routines" */
export type Program_Routines_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Program_Routines_Max_Order_By>;
  min?: Maybe<Program_Routines_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Program_Routines_Append_Input = {
  document?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "program_routines" */
export type Program_Routines_Arr_Rel_Insert_Input = {
  data: Array<Program_Routines_Insert_Input>;
  on_conflict?: Maybe<Program_Routines_On_Conflict>;
};

/** Boolean expression to filter rows from the table "program_routines". All fields are combined with a logical 'AND'. */
export type Program_Routines_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Program_Routines_Bool_Exp>>>;
  _not?: Maybe<Program_Routines_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Program_Routines_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  document?: Maybe<Jsonb_Comparison_Exp>;
  program_id?: Maybe<Uuid_Comparison_Exp>;
  publication_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "program_routines" */
export enum Program_Routines_Constraint {
  /** unique or primary key constraint */
  ProgramRoutinesPkey = 'program_routines_pkey',
  /** unique or primary key constraint */
  ProgramRoutinesPublicationIdKey = 'program_routines_publication_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Program_Routines_Delete_At_Path_Input = {
  document?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Program_Routines_Delete_Elem_Input = {
  document?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Program_Routines_Delete_Key_Input = {
  document?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "program_routines" */
export type Program_Routines_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  document?: Maybe<Scalars['jsonb']>;
  program_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Program_Routines_Max_Fields = {
  __typename?: 'program_routines_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  program_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "program_routines" */
export type Program_Routines_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  publication_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Program_Routines_Min_Fields = {
  __typename?: 'program_routines_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  program_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "program_routines" */
export type Program_Routines_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  publication_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "program_routines" */
export type Program_Routines_Mutation_Response = {
  __typename?: 'program_routines_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Program_Routines>;
};

/** input type for inserting object relation for remote table "program_routines" */
export type Program_Routines_Obj_Rel_Insert_Input = {
  data: Program_Routines_Insert_Input;
  on_conflict?: Maybe<Program_Routines_On_Conflict>;
};

/** on conflict condition type for table "program_routines" */
export type Program_Routines_On_Conflict = {
  constraint: Program_Routines_Constraint;
  update_columns: Array<Program_Routines_Update_Column>;
  where?: Maybe<Program_Routines_Bool_Exp>;
};

/** ordering options when selecting data from "program_routines" */
export type Program_Routines_Order_By = {
  created_at?: Maybe<Order_By>;
  document?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  publication_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "program_routines" */
export type Program_Routines_Pk_Columns_Input = {
  publication_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Program_Routines_Prepend_Input = {
  document?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "program_routines" */
export enum Program_Routines_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Document = 'document',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  PublicationId = 'publication_id'
}

/** input type for updating data in table "program_routines" */
export type Program_Routines_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  document?: Maybe<Scalars['jsonb']>;
  program_id?: Maybe<Scalars['uuid']>;
  publication_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "program_routines" */
export enum Program_Routines_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Document = 'document',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  PublicationId = 'publication_id'
}

/** columns and relationships of "programs" */
export type Programs = {
  __typename?: 'programs';
  /** An object relationship */
  coach: Coaches;
  coach_id: Scalars['String'];
  currency: Scalars['String'];
  id: Scalars['uuid'];
  price: Scalars['Int'];
  program_name: Scalars['String'];
  /** An array relationship */
  program_public_pages: Array<Program_Public_Pages>;
  /** An aggregated array relationship */
  program_public_pages_aggregate: Program_Public_Pages_Aggregate;
  /** An array relationship */
  program_publications: Array<Program_Publications>;
  /** An aggregated array relationship */
  program_publications_aggregate: Program_Publications_Aggregate;
  /** An array relationship */
  program_routines: Array<Program_Routines>;
  /** An aggregated array relationship */
  program_routines_aggregate: Program_Routines_Aggregate;
  selling_type: Scalars['String'];
  /** An array relationship */
  users_to_programs: Array<Users_To_Programs>;
  /** An aggregated array relationship */
  users_to_programs_aggregate: Users_To_Programs_Aggregate;
};


/** columns and relationships of "programs" */
export type ProgramsProgram_Public_PagesArgs = {
  distinct_on?: Maybe<Array<Program_Public_Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Public_Pages_Order_By>>;
  where?: Maybe<Program_Public_Pages_Bool_Exp>;
};


/** columns and relationships of "programs" */
export type ProgramsProgram_Public_Pages_AggregateArgs = {
  distinct_on?: Maybe<Array<Program_Public_Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Public_Pages_Order_By>>;
  where?: Maybe<Program_Public_Pages_Bool_Exp>;
};


/** columns and relationships of "programs" */
export type ProgramsProgram_PublicationsArgs = {
  distinct_on?: Maybe<Array<Program_Publications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Publications_Order_By>>;
  where?: Maybe<Program_Publications_Bool_Exp>;
};


/** columns and relationships of "programs" */
export type ProgramsProgram_Publications_AggregateArgs = {
  distinct_on?: Maybe<Array<Program_Publications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Publications_Order_By>>;
  where?: Maybe<Program_Publications_Bool_Exp>;
};


/** columns and relationships of "programs" */
export type ProgramsProgram_RoutinesArgs = {
  distinct_on?: Maybe<Array<Program_Routines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Routines_Order_By>>;
  where?: Maybe<Program_Routines_Bool_Exp>;
};


/** columns and relationships of "programs" */
export type ProgramsProgram_Routines_AggregateArgs = {
  distinct_on?: Maybe<Array<Program_Routines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Routines_Order_By>>;
  where?: Maybe<Program_Routines_Bool_Exp>;
};


/** columns and relationships of "programs" */
export type ProgramsUsers_To_ProgramsArgs = {
  distinct_on?: Maybe<Array<Users_To_Programs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_To_Programs_Order_By>>;
  where?: Maybe<Users_To_Programs_Bool_Exp>;
};


/** columns and relationships of "programs" */
export type ProgramsUsers_To_Programs_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_To_Programs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_To_Programs_Order_By>>;
  where?: Maybe<Users_To_Programs_Bool_Exp>;
};

/** aggregated selection of "programs" */
export type Programs_Aggregate = {
  __typename?: 'programs_aggregate';
  aggregate?: Maybe<Programs_Aggregate_Fields>;
  nodes: Array<Programs>;
};

/** aggregate fields of "programs" */
export type Programs_Aggregate_Fields = {
  __typename?: 'programs_aggregate_fields';
  avg?: Maybe<Programs_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Programs_Max_Fields>;
  min?: Maybe<Programs_Min_Fields>;
  stddev?: Maybe<Programs_Stddev_Fields>;
  stddev_pop?: Maybe<Programs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Programs_Stddev_Samp_Fields>;
  sum?: Maybe<Programs_Sum_Fields>;
  var_pop?: Maybe<Programs_Var_Pop_Fields>;
  var_samp?: Maybe<Programs_Var_Samp_Fields>;
  variance?: Maybe<Programs_Variance_Fields>;
};


/** aggregate fields of "programs" */
export type Programs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Programs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "programs" */
export type Programs_Aggregate_Order_By = {
  avg?: Maybe<Programs_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Programs_Max_Order_By>;
  min?: Maybe<Programs_Min_Order_By>;
  stddev?: Maybe<Programs_Stddev_Order_By>;
  stddev_pop?: Maybe<Programs_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Programs_Stddev_Samp_Order_By>;
  sum?: Maybe<Programs_Sum_Order_By>;
  var_pop?: Maybe<Programs_Var_Pop_Order_By>;
  var_samp?: Maybe<Programs_Var_Samp_Order_By>;
  variance?: Maybe<Programs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "programs" */
export type Programs_Arr_Rel_Insert_Input = {
  data: Array<Programs_Insert_Input>;
  on_conflict?: Maybe<Programs_On_Conflict>;
};

/** aggregate avg on columns */
export type Programs_Avg_Fields = {
  __typename?: 'programs_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "programs" */
export type Programs_Avg_Order_By = {
  price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "programs". All fields are combined with a logical 'AND'. */
export type Programs_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Programs_Bool_Exp>>>;
  _not?: Maybe<Programs_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Programs_Bool_Exp>>>;
  coach?: Maybe<Coaches_Bool_Exp>;
  coach_id?: Maybe<String_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  price?: Maybe<Int_Comparison_Exp>;
  program_name?: Maybe<String_Comparison_Exp>;
  program_public_pages?: Maybe<Program_Public_Pages_Bool_Exp>;
  program_publications?: Maybe<Program_Publications_Bool_Exp>;
  program_routines?: Maybe<Program_Routines_Bool_Exp>;
  selling_type?: Maybe<String_Comparison_Exp>;
  users_to_programs?: Maybe<Users_To_Programs_Bool_Exp>;
};

/** unique or primary key constraints on table "programs" */
export enum Programs_Constraint {
  /** unique or primary key constraint */
  ProgramsPkey = 'programs_pkey'
}

/** input type for incrementing integer column in table "programs" */
export type Programs_Inc_Input = {
  price?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "programs" */
export type Programs_Insert_Input = {
  coach?: Maybe<Coaches_Obj_Rel_Insert_Input>;
  coach_id?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  program_name?: Maybe<Scalars['String']>;
  program_public_pages?: Maybe<Program_Public_Pages_Arr_Rel_Insert_Input>;
  program_publications?: Maybe<Program_Publications_Arr_Rel_Insert_Input>;
  program_routines?: Maybe<Program_Routines_Arr_Rel_Insert_Input>;
  selling_type?: Maybe<Scalars['String']>;
  users_to_programs?: Maybe<Users_To_Programs_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Programs_Max_Fields = {
  __typename?: 'programs_max_fields';
  coach_id?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  program_name?: Maybe<Scalars['String']>;
  selling_type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "programs" */
export type Programs_Max_Order_By = {
  coach_id?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  program_name?: Maybe<Order_By>;
  selling_type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Programs_Min_Fields = {
  __typename?: 'programs_min_fields';
  coach_id?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  program_name?: Maybe<Scalars['String']>;
  selling_type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "programs" */
export type Programs_Min_Order_By = {
  coach_id?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  program_name?: Maybe<Order_By>;
  selling_type?: Maybe<Order_By>;
};

/** response of any mutation on the table "programs" */
export type Programs_Mutation_Response = {
  __typename?: 'programs_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Programs>;
};

/** input type for inserting object relation for remote table "programs" */
export type Programs_Obj_Rel_Insert_Input = {
  data: Programs_Insert_Input;
  on_conflict?: Maybe<Programs_On_Conflict>;
};

/** on conflict condition type for table "programs" */
export type Programs_On_Conflict = {
  constraint: Programs_Constraint;
  update_columns: Array<Programs_Update_Column>;
  where?: Maybe<Programs_Bool_Exp>;
};

/** ordering options when selecting data from "programs" */
export type Programs_Order_By = {
  coach?: Maybe<Coaches_Order_By>;
  coach_id?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  program_name?: Maybe<Order_By>;
  program_public_pages_aggregate?: Maybe<Program_Public_Pages_Aggregate_Order_By>;
  program_publications_aggregate?: Maybe<Program_Publications_Aggregate_Order_By>;
  program_routines_aggregate?: Maybe<Program_Routines_Aggregate_Order_By>;
  selling_type?: Maybe<Order_By>;
  users_to_programs_aggregate?: Maybe<Users_To_Programs_Aggregate_Order_By>;
};

/** primary key columns input for table: "programs" */
export type Programs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "programs" */
export enum Programs_Select_Column {
  /** column name */
  CoachId = 'coach_id',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  ProgramName = 'program_name',
  /** column name */
  SellingType = 'selling_type'
}

/** input type for updating data in table "programs" */
export type Programs_Set_Input = {
  coach_id?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  program_name?: Maybe<Scalars['String']>;
  selling_type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Programs_Stddev_Fields = {
  __typename?: 'programs_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "programs" */
export type Programs_Stddev_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Programs_Stddev_Pop_Fields = {
  __typename?: 'programs_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "programs" */
export type Programs_Stddev_Pop_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Programs_Stddev_Samp_Fields = {
  __typename?: 'programs_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "programs" */
export type Programs_Stddev_Samp_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Programs_Sum_Fields = {
  __typename?: 'programs_sum_fields';
  price?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "programs" */
export type Programs_Sum_Order_By = {
  price?: Maybe<Order_By>;
};

/** update columns of table "programs" */
export enum Programs_Update_Column {
  /** column name */
  CoachId = 'coach_id',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  ProgramName = 'program_name',
  /** column name */
  SellingType = 'selling_type'
}

/** aggregate var_pop on columns */
export type Programs_Var_Pop_Fields = {
  __typename?: 'programs_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "programs" */
export type Programs_Var_Pop_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Programs_Var_Samp_Fields = {
  __typename?: 'programs_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "programs" */
export type Programs_Var_Samp_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Programs_Variance_Fields = {
  __typename?: 'programs_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "programs" */
export type Programs_Variance_Order_By = {
  price?: Maybe<Order_By>;
};

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "coaches" */
  coaches: Array<Coaches>;
  /** fetch aggregated fields from the table: "coaches" */
  coaches_aggregate: Coaches_Aggregate;
  /** fetch data from the table: "coaches" using primary key columns */
  coaches_by_pk?: Maybe<Coaches>;
  /** fetch data from the table: "content_reports" */
  content_reports: Array<Content_Reports>;
  /** fetch aggregated fields from the table: "content_reports" */
  content_reports_aggregate: Content_Reports_Aggregate;
  /** fetch data from the table: "content_reports" using primary key columns */
  content_reports_by_pk?: Maybe<Content_Reports>;
  /** fetch data from the table: "create_free_checkout_sessions" */
  create_free_checkout_sessions: Array<Create_Free_Checkout_Sessions>;
  /** fetch aggregated fields from the table: "create_free_checkout_sessions" */
  create_free_checkout_sessions_aggregate: Create_Free_Checkout_Sessions_Aggregate;
  /** fetch data from the table: "create_free_checkout_sessions" using primary key columns */
  create_free_checkout_sessions_by_pk?: Maybe<Create_Free_Checkout_Sessions>;
  /** fetch data from the table: "create_free_checkout_sessions_statuses" */
  create_free_checkout_sessions_statuses: Array<Create_Free_Checkout_Sessions_Statuses>;
  /** fetch aggregated fields from the table: "create_free_checkout_sessions_statuses" */
  create_free_checkout_sessions_statuses_aggregate: Create_Free_Checkout_Sessions_Statuses_Aggregate;
  /** fetch data from the table: "create_free_checkout_sessions_statuses" using primary key columns */
  create_free_checkout_sessions_statuses_by_pk?: Maybe<Create_Free_Checkout_Sessions_Statuses>;
  /** fetch data from the table: "create_stripe_checkout_session_statuses" */
  create_stripe_checkout_session_statuses: Array<Create_Stripe_Checkout_Session_Statuses>;
  /** fetch aggregated fields from the table: "create_stripe_checkout_session_statuses" */
  create_stripe_checkout_session_statuses_aggregate: Create_Stripe_Checkout_Session_Statuses_Aggregate;
  /** fetch data from the table: "create_stripe_checkout_session_statuses" using primary key columns */
  create_stripe_checkout_session_statuses_by_pk?: Maybe<Create_Stripe_Checkout_Session_Statuses>;
  /** fetch data from the table: "create_stripe_checkout_sessions" */
  create_stripe_checkout_sessions: Array<Create_Stripe_Checkout_Sessions>;
  /** fetch aggregated fields from the table: "create_stripe_checkout_sessions" */
  create_stripe_checkout_sessions_aggregate: Create_Stripe_Checkout_Sessions_Aggregate;
  /** fetch data from the table: "create_stripe_checkout_sessions" using primary key columns */
  create_stripe_checkout_sessions_by_pk?: Maybe<Create_Stripe_Checkout_Sessions>;
  /** fetch data from the table: "default_available_programs" */
  default_available_programs: Array<Default_Available_Programs>;
  /** fetch aggregated fields from the table: "default_available_programs" */
  default_available_programs_aggregate: Default_Available_Programs_Aggregate;
  /** fetch data from the table: "default_available_programs" using primary key columns */
  default_available_programs_by_pk?: Maybe<Default_Available_Programs>;
  /** fetch data from the table: "program_public_pages" */
  program_public_pages: Array<Program_Public_Pages>;
  /** fetch aggregated fields from the table: "program_public_pages" */
  program_public_pages_aggregate: Program_Public_Pages_Aggregate;
  /** fetch data from the table: "program_public_pages" using primary key columns */
  program_public_pages_by_pk?: Maybe<Program_Public_Pages>;
  /** fetch data from the table: "program_publications" */
  program_publications: Array<Program_Publications>;
  /** fetch aggregated fields from the table: "program_publications" */
  program_publications_aggregate: Program_Publications_Aggregate;
  /** fetch data from the table: "program_publications" using primary key columns */
  program_publications_by_pk?: Maybe<Program_Publications>;
  /** fetch data from the table: "program_routines" */
  program_routines: Array<Program_Routines>;
  /** fetch aggregated fields from the table: "program_routines" */
  program_routines_aggregate: Program_Routines_Aggregate;
  /** fetch data from the table: "program_routines" using primary key columns */
  program_routines_by_pk?: Maybe<Program_Routines>;
  /** fetch data from the table: "programs" */
  programs: Array<Programs>;
  /** fetch aggregated fields from the table: "programs" */
  programs_aggregate: Programs_Aggregate;
  /** fetch data from the table: "programs" using primary key columns */
  programs_by_pk?: Maybe<Programs>;
  /** fetch data from the table: "stripe_checkout_session_statuses" */
  stripe_checkout_session_statuses: Array<Stripe_Checkout_Session_Statuses>;
  /** fetch aggregated fields from the table: "stripe_checkout_session_statuses" */
  stripe_checkout_session_statuses_aggregate: Stripe_Checkout_Session_Statuses_Aggregate;
  /** fetch data from the table: "stripe_checkout_session_statuses" using primary key columns */
  stripe_checkout_session_statuses_by_pk?: Maybe<Stripe_Checkout_Session_Statuses>;
  /** fetch data from the table: "stripe_checkout_sessions" */
  stripe_checkout_sessions: Array<Stripe_Checkout_Sessions>;
  /** fetch aggregated fields from the table: "stripe_checkout_sessions" */
  stripe_checkout_sessions_aggregate: Stripe_Checkout_Sessions_Aggregate;
  /** fetch data from the table: "stripe_checkout_sessions" using primary key columns */
  stripe_checkout_sessions_by_pk?: Maybe<Stripe_Checkout_Sessions>;
  /** fetch data from the table: "user_readiness" */
  user_readiness: Array<User_Readiness>;
  /** fetch aggregated fields from the table: "user_readiness" */
  user_readiness_aggregate: User_Readiness_Aggregate;
  /** fetch data from the table: "user_readiness" using primary key columns */
  user_readiness_by_pk?: Maybe<User_Readiness>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_to_programs" */
  users_to_programs: Array<Users_To_Programs>;
  /** fetch aggregated fields from the table: "users_to_programs" */
  users_to_programs_aggregate: Users_To_Programs_Aggregate;
  /** fetch data from the table: "users_to_programs" using primary key columns */
  users_to_programs_by_pk?: Maybe<Users_To_Programs>;
};


/** query root */
export type Query_RootCoachesArgs = {
  distinct_on?: Maybe<Array<Coaches_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coaches_Order_By>>;
  where?: Maybe<Coaches_Bool_Exp>;
};


/** query root */
export type Query_RootCoaches_AggregateArgs = {
  distinct_on?: Maybe<Array<Coaches_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coaches_Order_By>>;
  where?: Maybe<Coaches_Bool_Exp>;
};


/** query root */
export type Query_RootCoaches_By_PkArgs = {
  id: Scalars['String'];
};


/** query root */
export type Query_RootContent_ReportsArgs = {
  distinct_on?: Maybe<Array<Content_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Content_Reports_Order_By>>;
  where?: Maybe<Content_Reports_Bool_Exp>;
};


/** query root */
export type Query_RootContent_Reports_AggregateArgs = {
  distinct_on?: Maybe<Array<Content_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Content_Reports_Order_By>>;
  where?: Maybe<Content_Reports_Bool_Exp>;
};


/** query root */
export type Query_RootContent_Reports_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCreate_Free_Checkout_SessionsArgs = {
  distinct_on?: Maybe<Array<Create_Free_Checkout_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Create_Free_Checkout_Sessions_Order_By>>;
  where?: Maybe<Create_Free_Checkout_Sessions_Bool_Exp>;
};


/** query root */
export type Query_RootCreate_Free_Checkout_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Create_Free_Checkout_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Create_Free_Checkout_Sessions_Order_By>>;
  where?: Maybe<Create_Free_Checkout_Sessions_Bool_Exp>;
};


/** query root */
export type Query_RootCreate_Free_Checkout_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
  program_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** query root */
export type Query_RootCreate_Free_Checkout_Sessions_StatusesArgs = {
  distinct_on?: Maybe<Array<Create_Free_Checkout_Sessions_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Create_Free_Checkout_Sessions_Statuses_Order_By>>;
  where?: Maybe<Create_Free_Checkout_Sessions_Statuses_Bool_Exp>;
};


/** query root */
export type Query_RootCreate_Free_Checkout_Sessions_Statuses_AggregateArgs = {
  distinct_on?: Maybe<Array<Create_Free_Checkout_Sessions_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Create_Free_Checkout_Sessions_Statuses_Order_By>>;
  where?: Maybe<Create_Free_Checkout_Sessions_Statuses_Bool_Exp>;
};


/** query root */
export type Query_RootCreate_Free_Checkout_Sessions_Statuses_By_PkArgs = {
  status: Scalars['String'];
};


/** query root */
export type Query_RootCreate_Stripe_Checkout_Session_StatusesArgs = {
  distinct_on?: Maybe<Array<Create_Stripe_Checkout_Session_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Create_Stripe_Checkout_Session_Statuses_Order_By>>;
  where?: Maybe<Create_Stripe_Checkout_Session_Statuses_Bool_Exp>;
};


/** query root */
export type Query_RootCreate_Stripe_Checkout_Session_Statuses_AggregateArgs = {
  distinct_on?: Maybe<Array<Create_Stripe_Checkout_Session_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Create_Stripe_Checkout_Session_Statuses_Order_By>>;
  where?: Maybe<Create_Stripe_Checkout_Session_Statuses_Bool_Exp>;
};


/** query root */
export type Query_RootCreate_Stripe_Checkout_Session_Statuses_By_PkArgs = {
  status: Scalars['String'];
};


/** query root */
export type Query_RootCreate_Stripe_Checkout_SessionsArgs = {
  distinct_on?: Maybe<Array<Create_Stripe_Checkout_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Create_Stripe_Checkout_Sessions_Order_By>>;
  where?: Maybe<Create_Stripe_Checkout_Sessions_Bool_Exp>;
};


/** query root */
export type Query_RootCreate_Stripe_Checkout_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Create_Stripe_Checkout_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Create_Stripe_Checkout_Sessions_Order_By>>;
  where?: Maybe<Create_Stripe_Checkout_Sessions_Bool_Exp>;
};


/** query root */
export type Query_RootCreate_Stripe_Checkout_Sessions_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  program_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** query root */
export type Query_RootDefault_Available_ProgramsArgs = {
  distinct_on?: Maybe<Array<Default_Available_Programs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Default_Available_Programs_Order_By>>;
  where?: Maybe<Default_Available_Programs_Bool_Exp>;
};


/** query root */
export type Query_RootDefault_Available_Programs_AggregateArgs = {
  distinct_on?: Maybe<Array<Default_Available_Programs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Default_Available_Programs_Order_By>>;
  where?: Maybe<Default_Available_Programs_Bool_Exp>;
};


/** query root */
export type Query_RootDefault_Available_Programs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootProgram_Public_PagesArgs = {
  distinct_on?: Maybe<Array<Program_Public_Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Public_Pages_Order_By>>;
  where?: Maybe<Program_Public_Pages_Bool_Exp>;
};


/** query root */
export type Query_RootProgram_Public_Pages_AggregateArgs = {
  distinct_on?: Maybe<Array<Program_Public_Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Public_Pages_Order_By>>;
  where?: Maybe<Program_Public_Pages_Bool_Exp>;
};


/** query root */
export type Query_RootProgram_Public_Pages_By_PkArgs = {
  program_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** query root */
export type Query_RootProgram_PublicationsArgs = {
  distinct_on?: Maybe<Array<Program_Publications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Publications_Order_By>>;
  where?: Maybe<Program_Publications_Bool_Exp>;
};


/** query root */
export type Query_RootProgram_Publications_AggregateArgs = {
  distinct_on?: Maybe<Array<Program_Publications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Publications_Order_By>>;
  where?: Maybe<Program_Publications_Bool_Exp>;
};


/** query root */
export type Query_RootProgram_Publications_By_PkArgs = {
  publication_id: Scalars['uuid'];
};


/** query root */
export type Query_RootProgram_RoutinesArgs = {
  distinct_on?: Maybe<Array<Program_Routines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Routines_Order_By>>;
  where?: Maybe<Program_Routines_Bool_Exp>;
};


/** query root */
export type Query_RootProgram_Routines_AggregateArgs = {
  distinct_on?: Maybe<Array<Program_Routines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Routines_Order_By>>;
  where?: Maybe<Program_Routines_Bool_Exp>;
};


/** query root */
export type Query_RootProgram_Routines_By_PkArgs = {
  publication_id: Scalars['uuid'];
};


/** query root */
export type Query_RootProgramsArgs = {
  distinct_on?: Maybe<Array<Programs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Programs_Order_By>>;
  where?: Maybe<Programs_Bool_Exp>;
};


/** query root */
export type Query_RootPrograms_AggregateArgs = {
  distinct_on?: Maybe<Array<Programs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Programs_Order_By>>;
  where?: Maybe<Programs_Bool_Exp>;
};


/** query root */
export type Query_RootPrograms_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootStripe_Checkout_Session_StatusesArgs = {
  distinct_on?: Maybe<Array<Stripe_Checkout_Session_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stripe_Checkout_Session_Statuses_Order_By>>;
  where?: Maybe<Stripe_Checkout_Session_Statuses_Bool_Exp>;
};


/** query root */
export type Query_RootStripe_Checkout_Session_Statuses_AggregateArgs = {
  distinct_on?: Maybe<Array<Stripe_Checkout_Session_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stripe_Checkout_Session_Statuses_Order_By>>;
  where?: Maybe<Stripe_Checkout_Session_Statuses_Bool_Exp>;
};


/** query root */
export type Query_RootStripe_Checkout_Session_Statuses_By_PkArgs = {
  status: Scalars['String'];
};


/** query root */
export type Query_RootStripe_Checkout_SessionsArgs = {
  distinct_on?: Maybe<Array<Stripe_Checkout_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stripe_Checkout_Sessions_Order_By>>;
  where?: Maybe<Stripe_Checkout_Sessions_Bool_Exp>;
};


/** query root */
export type Query_RootStripe_Checkout_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Stripe_Checkout_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stripe_Checkout_Sessions_Order_By>>;
  where?: Maybe<Stripe_Checkout_Sessions_Bool_Exp>;
};


/** query root */
export type Query_RootStripe_Checkout_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootUser_ReadinessArgs = {
  distinct_on?: Maybe<Array<User_Readiness_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Readiness_Order_By>>;
  where?: Maybe<User_Readiness_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Readiness_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Readiness_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Readiness_Order_By>>;
  where?: Maybe<User_Readiness_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Readiness_By_PkArgs = {
  user_id: Scalars['String'];
};


/** query root */
export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};


/** query root */
export type Query_RootUsers_To_ProgramsArgs = {
  distinct_on?: Maybe<Array<Users_To_Programs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_To_Programs_Order_By>>;
  where?: Maybe<Users_To_Programs_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_To_Programs_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_To_Programs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_To_Programs_Order_By>>;
  where?: Maybe<Users_To_Programs_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_To_Programs_By_PkArgs = {
  program_id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** columns and relationships of "stripe_checkout_session_statuses" */
export type Stripe_Checkout_Session_Statuses = {
  __typename?: 'stripe_checkout_session_statuses';
  status: Scalars['String'];
};

/** aggregated selection of "stripe_checkout_session_statuses" */
export type Stripe_Checkout_Session_Statuses_Aggregate = {
  __typename?: 'stripe_checkout_session_statuses_aggregate';
  aggregate?: Maybe<Stripe_Checkout_Session_Statuses_Aggregate_Fields>;
  nodes: Array<Stripe_Checkout_Session_Statuses>;
};

/** aggregate fields of "stripe_checkout_session_statuses" */
export type Stripe_Checkout_Session_Statuses_Aggregate_Fields = {
  __typename?: 'stripe_checkout_session_statuses_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stripe_Checkout_Session_Statuses_Max_Fields>;
  min?: Maybe<Stripe_Checkout_Session_Statuses_Min_Fields>;
};


/** aggregate fields of "stripe_checkout_session_statuses" */
export type Stripe_Checkout_Session_Statuses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stripe_Checkout_Session_Statuses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stripe_checkout_session_statuses" */
export type Stripe_Checkout_Session_Statuses_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Stripe_Checkout_Session_Statuses_Max_Order_By>;
  min?: Maybe<Stripe_Checkout_Session_Statuses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "stripe_checkout_session_statuses" */
export type Stripe_Checkout_Session_Statuses_Arr_Rel_Insert_Input = {
  data: Array<Stripe_Checkout_Session_Statuses_Insert_Input>;
  on_conflict?: Maybe<Stripe_Checkout_Session_Statuses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "stripe_checkout_session_statuses". All fields are combined with a logical 'AND'. */
export type Stripe_Checkout_Session_Statuses_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Stripe_Checkout_Session_Statuses_Bool_Exp>>>;
  _not?: Maybe<Stripe_Checkout_Session_Statuses_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Stripe_Checkout_Session_Statuses_Bool_Exp>>>;
  status?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_checkout_session_statuses" */
export enum Stripe_Checkout_Session_Statuses_Constraint {
  /** unique or primary key constraint */
  StripeCheckoutSessionStatusesPkey = 'stripe_checkout_session_statuses_pkey'
}

export enum Stripe_Checkout_Session_Statuses_Enum {
  Done = 'DONE',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

/** expression to compare columns of type stripe_checkout_session_statuses_enum. All fields are combined with logical 'AND'. */
export type Stripe_Checkout_Session_Statuses_Enum_Comparison_Exp = {
  _eq?: Maybe<Stripe_Checkout_Session_Statuses_Enum>;
  _in?: Maybe<Array<Stripe_Checkout_Session_Statuses_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Stripe_Checkout_Session_Statuses_Enum>;
  _nin?: Maybe<Array<Stripe_Checkout_Session_Statuses_Enum>>;
};

/** input type for inserting data into table "stripe_checkout_session_statuses" */
export type Stripe_Checkout_Session_Statuses_Insert_Input = {
  status?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stripe_Checkout_Session_Statuses_Max_Fields = {
  __typename?: 'stripe_checkout_session_statuses_max_fields';
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stripe_checkout_session_statuses" */
export type Stripe_Checkout_Session_Statuses_Max_Order_By = {
  status?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stripe_Checkout_Session_Statuses_Min_Fields = {
  __typename?: 'stripe_checkout_session_statuses_min_fields';
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stripe_checkout_session_statuses" */
export type Stripe_Checkout_Session_Statuses_Min_Order_By = {
  status?: Maybe<Order_By>;
};

/** response of any mutation on the table "stripe_checkout_session_statuses" */
export type Stripe_Checkout_Session_Statuses_Mutation_Response = {
  __typename?: 'stripe_checkout_session_statuses_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stripe_Checkout_Session_Statuses>;
};

/** input type for inserting object relation for remote table "stripe_checkout_session_statuses" */
export type Stripe_Checkout_Session_Statuses_Obj_Rel_Insert_Input = {
  data: Stripe_Checkout_Session_Statuses_Insert_Input;
  on_conflict?: Maybe<Stripe_Checkout_Session_Statuses_On_Conflict>;
};

/** on conflict condition type for table "stripe_checkout_session_statuses" */
export type Stripe_Checkout_Session_Statuses_On_Conflict = {
  constraint: Stripe_Checkout_Session_Statuses_Constraint;
  update_columns: Array<Stripe_Checkout_Session_Statuses_Update_Column>;
  where?: Maybe<Stripe_Checkout_Session_Statuses_Bool_Exp>;
};

/** ordering options when selecting data from "stripe_checkout_session_statuses" */
export type Stripe_Checkout_Session_Statuses_Order_By = {
  status?: Maybe<Order_By>;
};

/** primary key columns input for table: "stripe_checkout_session_statuses" */
export type Stripe_Checkout_Session_Statuses_Pk_Columns_Input = {
  status: Scalars['String'];
};

/** select columns of table "stripe_checkout_session_statuses" */
export enum Stripe_Checkout_Session_Statuses_Select_Column {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "stripe_checkout_session_statuses" */
export type Stripe_Checkout_Session_Statuses_Set_Input = {
  status?: Maybe<Scalars['String']>;
};

/** update columns of table "stripe_checkout_session_statuses" */
export enum Stripe_Checkout_Session_Statuses_Update_Column {
  /** column name */
  Status = 'status'
}

/** columns and relationships of "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions = {
  __typename?: 'stripe_checkout_sessions';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  status: Stripe_Checkout_Session_Statuses_Enum;
  stripe_checkout_session_id: Scalars['String'];
  user_id: Scalars['String'];
};

/** aggregated selection of "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Aggregate = {
  __typename?: 'stripe_checkout_sessions_aggregate';
  aggregate?: Maybe<Stripe_Checkout_Sessions_Aggregate_Fields>;
  nodes: Array<Stripe_Checkout_Sessions>;
};

/** aggregate fields of "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Aggregate_Fields = {
  __typename?: 'stripe_checkout_sessions_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stripe_Checkout_Sessions_Max_Fields>;
  min?: Maybe<Stripe_Checkout_Sessions_Min_Fields>;
};


/** aggregate fields of "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stripe_Checkout_Sessions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Stripe_Checkout_Sessions_Max_Order_By>;
  min?: Maybe<Stripe_Checkout_Sessions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Stripe_Checkout_Sessions_Insert_Input>;
  on_conflict?: Maybe<Stripe_Checkout_Sessions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "stripe_checkout_sessions". All fields are combined with a logical 'AND'. */
export type Stripe_Checkout_Sessions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Stripe_Checkout_Sessions_Bool_Exp>>>;
  _not?: Maybe<Stripe_Checkout_Sessions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Stripe_Checkout_Sessions_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<Stripe_Checkout_Session_Statuses_Enum_Comparison_Exp>;
  stripe_checkout_session_id?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_checkout_sessions" */
export enum Stripe_Checkout_Sessions_Constraint {
  /** unique or primary key constraint */
  StripeCheckoutSessionsPkey = 'stripe_checkout_sessions_pkey'
}

/** input type for inserting data into table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Stripe_Checkout_Session_Statuses_Enum>;
  stripe_checkout_session_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stripe_Checkout_Sessions_Max_Fields = {
  __typename?: 'stripe_checkout_sessions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  stripe_checkout_session_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  stripe_checkout_session_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stripe_Checkout_Sessions_Min_Fields = {
  __typename?: 'stripe_checkout_sessions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  stripe_checkout_session_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  stripe_checkout_session_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Mutation_Response = {
  __typename?: 'stripe_checkout_sessions_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stripe_Checkout_Sessions>;
};

/** input type for inserting object relation for remote table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Obj_Rel_Insert_Input = {
  data: Stripe_Checkout_Sessions_Insert_Input;
  on_conflict?: Maybe<Stripe_Checkout_Sessions_On_Conflict>;
};

/** on conflict condition type for table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_On_Conflict = {
  constraint: Stripe_Checkout_Sessions_Constraint;
  update_columns: Array<Stripe_Checkout_Sessions_Update_Column>;
  where?: Maybe<Stripe_Checkout_Sessions_Bool_Exp>;
};

/** ordering options when selecting data from "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  stripe_checkout_session_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "stripe_checkout_sessions" */
export enum Stripe_Checkout_Sessions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  StripeCheckoutSessionId = 'stripe_checkout_session_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Stripe_Checkout_Session_Statuses_Enum>;
  stripe_checkout_session_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "stripe_checkout_sessions" */
export enum Stripe_Checkout_Sessions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  StripeCheckoutSessionId = 'stripe_checkout_session_id',
  /** column name */
  UserId = 'user_id'
}

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "coaches" */
  coaches: Array<Coaches>;
  /** fetch aggregated fields from the table: "coaches" */
  coaches_aggregate: Coaches_Aggregate;
  /** fetch data from the table: "coaches" using primary key columns */
  coaches_by_pk?: Maybe<Coaches>;
  /** fetch data from the table: "content_reports" */
  content_reports: Array<Content_Reports>;
  /** fetch aggregated fields from the table: "content_reports" */
  content_reports_aggregate: Content_Reports_Aggregate;
  /** fetch data from the table: "content_reports" using primary key columns */
  content_reports_by_pk?: Maybe<Content_Reports>;
  /** fetch data from the table: "create_free_checkout_sessions" */
  create_free_checkout_sessions: Array<Create_Free_Checkout_Sessions>;
  /** fetch aggregated fields from the table: "create_free_checkout_sessions" */
  create_free_checkout_sessions_aggregate: Create_Free_Checkout_Sessions_Aggregate;
  /** fetch data from the table: "create_free_checkout_sessions" using primary key columns */
  create_free_checkout_sessions_by_pk?: Maybe<Create_Free_Checkout_Sessions>;
  /** fetch data from the table: "create_free_checkout_sessions_statuses" */
  create_free_checkout_sessions_statuses: Array<Create_Free_Checkout_Sessions_Statuses>;
  /** fetch aggregated fields from the table: "create_free_checkout_sessions_statuses" */
  create_free_checkout_sessions_statuses_aggregate: Create_Free_Checkout_Sessions_Statuses_Aggregate;
  /** fetch data from the table: "create_free_checkout_sessions_statuses" using primary key columns */
  create_free_checkout_sessions_statuses_by_pk?: Maybe<Create_Free_Checkout_Sessions_Statuses>;
  /** fetch data from the table: "create_stripe_checkout_session_statuses" */
  create_stripe_checkout_session_statuses: Array<Create_Stripe_Checkout_Session_Statuses>;
  /** fetch aggregated fields from the table: "create_stripe_checkout_session_statuses" */
  create_stripe_checkout_session_statuses_aggregate: Create_Stripe_Checkout_Session_Statuses_Aggregate;
  /** fetch data from the table: "create_stripe_checkout_session_statuses" using primary key columns */
  create_stripe_checkout_session_statuses_by_pk?: Maybe<Create_Stripe_Checkout_Session_Statuses>;
  /** fetch data from the table: "create_stripe_checkout_sessions" */
  create_stripe_checkout_sessions: Array<Create_Stripe_Checkout_Sessions>;
  /** fetch aggregated fields from the table: "create_stripe_checkout_sessions" */
  create_stripe_checkout_sessions_aggregate: Create_Stripe_Checkout_Sessions_Aggregate;
  /** fetch data from the table: "create_stripe_checkout_sessions" using primary key columns */
  create_stripe_checkout_sessions_by_pk?: Maybe<Create_Stripe_Checkout_Sessions>;
  /** fetch data from the table: "default_available_programs" */
  default_available_programs: Array<Default_Available_Programs>;
  /** fetch aggregated fields from the table: "default_available_programs" */
  default_available_programs_aggregate: Default_Available_Programs_Aggregate;
  /** fetch data from the table: "default_available_programs" using primary key columns */
  default_available_programs_by_pk?: Maybe<Default_Available_Programs>;
  /** fetch data from the table: "program_public_pages" */
  program_public_pages: Array<Program_Public_Pages>;
  /** fetch aggregated fields from the table: "program_public_pages" */
  program_public_pages_aggregate: Program_Public_Pages_Aggregate;
  /** fetch data from the table: "program_public_pages" using primary key columns */
  program_public_pages_by_pk?: Maybe<Program_Public_Pages>;
  /** fetch data from the table: "program_publications" */
  program_publications: Array<Program_Publications>;
  /** fetch aggregated fields from the table: "program_publications" */
  program_publications_aggregate: Program_Publications_Aggregate;
  /** fetch data from the table: "program_publications" using primary key columns */
  program_publications_by_pk?: Maybe<Program_Publications>;
  /** fetch data from the table: "program_routines" */
  program_routines: Array<Program_Routines>;
  /** fetch aggregated fields from the table: "program_routines" */
  program_routines_aggregate: Program_Routines_Aggregate;
  /** fetch data from the table: "program_routines" using primary key columns */
  program_routines_by_pk?: Maybe<Program_Routines>;
  /** fetch data from the table: "programs" */
  programs: Array<Programs>;
  /** fetch aggregated fields from the table: "programs" */
  programs_aggregate: Programs_Aggregate;
  /** fetch data from the table: "programs" using primary key columns */
  programs_by_pk?: Maybe<Programs>;
  /** fetch data from the table: "stripe_checkout_session_statuses" */
  stripe_checkout_session_statuses: Array<Stripe_Checkout_Session_Statuses>;
  /** fetch aggregated fields from the table: "stripe_checkout_session_statuses" */
  stripe_checkout_session_statuses_aggregate: Stripe_Checkout_Session_Statuses_Aggregate;
  /** fetch data from the table: "stripe_checkout_session_statuses" using primary key columns */
  stripe_checkout_session_statuses_by_pk?: Maybe<Stripe_Checkout_Session_Statuses>;
  /** fetch data from the table: "stripe_checkout_sessions" */
  stripe_checkout_sessions: Array<Stripe_Checkout_Sessions>;
  /** fetch aggregated fields from the table: "stripe_checkout_sessions" */
  stripe_checkout_sessions_aggregate: Stripe_Checkout_Sessions_Aggregate;
  /** fetch data from the table: "stripe_checkout_sessions" using primary key columns */
  stripe_checkout_sessions_by_pk?: Maybe<Stripe_Checkout_Sessions>;
  /** fetch data from the table: "user_readiness" */
  user_readiness: Array<User_Readiness>;
  /** fetch aggregated fields from the table: "user_readiness" */
  user_readiness_aggregate: User_Readiness_Aggregate;
  /** fetch data from the table: "user_readiness" using primary key columns */
  user_readiness_by_pk?: Maybe<User_Readiness>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_to_programs" */
  users_to_programs: Array<Users_To_Programs>;
  /** fetch aggregated fields from the table: "users_to_programs" */
  users_to_programs_aggregate: Users_To_Programs_Aggregate;
  /** fetch data from the table: "users_to_programs" using primary key columns */
  users_to_programs_by_pk?: Maybe<Users_To_Programs>;
};


/** subscription root */
export type Subscription_RootCoachesArgs = {
  distinct_on?: Maybe<Array<Coaches_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coaches_Order_By>>;
  where?: Maybe<Coaches_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCoaches_AggregateArgs = {
  distinct_on?: Maybe<Array<Coaches_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coaches_Order_By>>;
  where?: Maybe<Coaches_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCoaches_By_PkArgs = {
  id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootContent_ReportsArgs = {
  distinct_on?: Maybe<Array<Content_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Content_Reports_Order_By>>;
  where?: Maybe<Content_Reports_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContent_Reports_AggregateArgs = {
  distinct_on?: Maybe<Array<Content_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Content_Reports_Order_By>>;
  where?: Maybe<Content_Reports_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContent_Reports_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCreate_Free_Checkout_SessionsArgs = {
  distinct_on?: Maybe<Array<Create_Free_Checkout_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Create_Free_Checkout_Sessions_Order_By>>;
  where?: Maybe<Create_Free_Checkout_Sessions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCreate_Free_Checkout_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Create_Free_Checkout_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Create_Free_Checkout_Sessions_Order_By>>;
  where?: Maybe<Create_Free_Checkout_Sessions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCreate_Free_Checkout_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
  program_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootCreate_Free_Checkout_Sessions_StatusesArgs = {
  distinct_on?: Maybe<Array<Create_Free_Checkout_Sessions_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Create_Free_Checkout_Sessions_Statuses_Order_By>>;
  where?: Maybe<Create_Free_Checkout_Sessions_Statuses_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCreate_Free_Checkout_Sessions_Statuses_AggregateArgs = {
  distinct_on?: Maybe<Array<Create_Free_Checkout_Sessions_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Create_Free_Checkout_Sessions_Statuses_Order_By>>;
  where?: Maybe<Create_Free_Checkout_Sessions_Statuses_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCreate_Free_Checkout_Sessions_Statuses_By_PkArgs = {
  status: Scalars['String'];
};


/** subscription root */
export type Subscription_RootCreate_Stripe_Checkout_Session_StatusesArgs = {
  distinct_on?: Maybe<Array<Create_Stripe_Checkout_Session_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Create_Stripe_Checkout_Session_Statuses_Order_By>>;
  where?: Maybe<Create_Stripe_Checkout_Session_Statuses_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCreate_Stripe_Checkout_Session_Statuses_AggregateArgs = {
  distinct_on?: Maybe<Array<Create_Stripe_Checkout_Session_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Create_Stripe_Checkout_Session_Statuses_Order_By>>;
  where?: Maybe<Create_Stripe_Checkout_Session_Statuses_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCreate_Stripe_Checkout_Session_Statuses_By_PkArgs = {
  status: Scalars['String'];
};


/** subscription root */
export type Subscription_RootCreate_Stripe_Checkout_SessionsArgs = {
  distinct_on?: Maybe<Array<Create_Stripe_Checkout_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Create_Stripe_Checkout_Sessions_Order_By>>;
  where?: Maybe<Create_Stripe_Checkout_Sessions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCreate_Stripe_Checkout_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Create_Stripe_Checkout_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Create_Stripe_Checkout_Sessions_Order_By>>;
  where?: Maybe<Create_Stripe_Checkout_Sessions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCreate_Stripe_Checkout_Sessions_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  program_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootDefault_Available_ProgramsArgs = {
  distinct_on?: Maybe<Array<Default_Available_Programs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Default_Available_Programs_Order_By>>;
  where?: Maybe<Default_Available_Programs_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDefault_Available_Programs_AggregateArgs = {
  distinct_on?: Maybe<Array<Default_Available_Programs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Default_Available_Programs_Order_By>>;
  where?: Maybe<Default_Available_Programs_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDefault_Available_Programs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootProgram_Public_PagesArgs = {
  distinct_on?: Maybe<Array<Program_Public_Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Public_Pages_Order_By>>;
  where?: Maybe<Program_Public_Pages_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProgram_Public_Pages_AggregateArgs = {
  distinct_on?: Maybe<Array<Program_Public_Pages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Public_Pages_Order_By>>;
  where?: Maybe<Program_Public_Pages_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProgram_Public_Pages_By_PkArgs = {
  program_id: Scalars['uuid'];
  publication_id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootProgram_PublicationsArgs = {
  distinct_on?: Maybe<Array<Program_Publications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Publications_Order_By>>;
  where?: Maybe<Program_Publications_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProgram_Publications_AggregateArgs = {
  distinct_on?: Maybe<Array<Program_Publications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Publications_Order_By>>;
  where?: Maybe<Program_Publications_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProgram_Publications_By_PkArgs = {
  publication_id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootProgram_RoutinesArgs = {
  distinct_on?: Maybe<Array<Program_Routines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Routines_Order_By>>;
  where?: Maybe<Program_Routines_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProgram_Routines_AggregateArgs = {
  distinct_on?: Maybe<Array<Program_Routines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Program_Routines_Order_By>>;
  where?: Maybe<Program_Routines_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProgram_Routines_By_PkArgs = {
  publication_id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootProgramsArgs = {
  distinct_on?: Maybe<Array<Programs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Programs_Order_By>>;
  where?: Maybe<Programs_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPrograms_AggregateArgs = {
  distinct_on?: Maybe<Array<Programs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Programs_Order_By>>;
  where?: Maybe<Programs_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPrograms_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootStripe_Checkout_Session_StatusesArgs = {
  distinct_on?: Maybe<Array<Stripe_Checkout_Session_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stripe_Checkout_Session_Statuses_Order_By>>;
  where?: Maybe<Stripe_Checkout_Session_Statuses_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStripe_Checkout_Session_Statuses_AggregateArgs = {
  distinct_on?: Maybe<Array<Stripe_Checkout_Session_Statuses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stripe_Checkout_Session_Statuses_Order_By>>;
  where?: Maybe<Stripe_Checkout_Session_Statuses_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStripe_Checkout_Session_Statuses_By_PkArgs = {
  status: Scalars['String'];
};


/** subscription root */
export type Subscription_RootStripe_Checkout_SessionsArgs = {
  distinct_on?: Maybe<Array<Stripe_Checkout_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stripe_Checkout_Sessions_Order_By>>;
  where?: Maybe<Stripe_Checkout_Sessions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStripe_Checkout_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Stripe_Checkout_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stripe_Checkout_Sessions_Order_By>>;
  where?: Maybe<Stripe_Checkout_Sessions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStripe_Checkout_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootUser_ReadinessArgs = {
  distinct_on?: Maybe<Array<User_Readiness_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Readiness_Order_By>>;
  where?: Maybe<User_Readiness_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Readiness_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Readiness_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Readiness_Order_By>>;
  where?: Maybe<User_Readiness_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Readiness_By_PkArgs = {
  user_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootUsers_To_ProgramsArgs = {
  distinct_on?: Maybe<Array<Users_To_Programs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_To_Programs_Order_By>>;
  where?: Maybe<Users_To_Programs_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_To_Programs_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_To_Programs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_To_Programs_Order_By>>;
  where?: Maybe<Users_To_Programs_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_To_Programs_By_PkArgs = {
  program_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user_readiness" */
export type User_Readiness = {
  __typename?: 'user_readiness';
  ready: Scalars['Boolean'];
  user_id: Scalars['String'];
};

/** aggregated selection of "user_readiness" */
export type User_Readiness_Aggregate = {
  __typename?: 'user_readiness_aggregate';
  aggregate?: Maybe<User_Readiness_Aggregate_Fields>;
  nodes: Array<User_Readiness>;
};

/** aggregate fields of "user_readiness" */
export type User_Readiness_Aggregate_Fields = {
  __typename?: 'user_readiness_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Readiness_Max_Fields>;
  min?: Maybe<User_Readiness_Min_Fields>;
};


/** aggregate fields of "user_readiness" */
export type User_Readiness_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Readiness_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_readiness" */
export type User_Readiness_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Readiness_Max_Order_By>;
  min?: Maybe<User_Readiness_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_readiness" */
export type User_Readiness_Arr_Rel_Insert_Input = {
  data: Array<User_Readiness_Insert_Input>;
  on_conflict?: Maybe<User_Readiness_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_readiness". All fields are combined with a logical 'AND'. */
export type User_Readiness_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Readiness_Bool_Exp>>>;
  _not?: Maybe<User_Readiness_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Readiness_Bool_Exp>>>;
  ready?: Maybe<Boolean_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_readiness" */
export enum User_Readiness_Constraint {
  /** unique or primary key constraint */
  UserReadinessPkey = 'user_readiness_pkey'
}

/** input type for inserting data into table "user_readiness" */
export type User_Readiness_Insert_Input = {
  ready?: Maybe<Scalars['Boolean']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Readiness_Max_Fields = {
  __typename?: 'user_readiness_max_fields';
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_readiness" */
export type User_Readiness_Max_Order_By = {
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Readiness_Min_Fields = {
  __typename?: 'user_readiness_min_fields';
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_readiness" */
export type User_Readiness_Min_Order_By = {
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_readiness" */
export type User_Readiness_Mutation_Response = {
  __typename?: 'user_readiness_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Readiness>;
};

/** input type for inserting object relation for remote table "user_readiness" */
export type User_Readiness_Obj_Rel_Insert_Input = {
  data: User_Readiness_Insert_Input;
  on_conflict?: Maybe<User_Readiness_On_Conflict>;
};

/** on conflict condition type for table "user_readiness" */
export type User_Readiness_On_Conflict = {
  constraint: User_Readiness_Constraint;
  update_columns: Array<User_Readiness_Update_Column>;
  where?: Maybe<User_Readiness_Bool_Exp>;
};

/** ordering options when selecting data from "user_readiness" */
export type User_Readiness_Order_By = {
  ready?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_readiness" */
export type User_Readiness_Pk_Columns_Input = {
  user_id: Scalars['String'];
};

/** select columns of table "user_readiness" */
export enum User_Readiness_Select_Column {
  /** column name */
  Ready = 'ready',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_readiness" */
export type User_Readiness_Set_Input = {
  ready?: Maybe<Scalars['Boolean']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "user_readiness" */
export enum User_Readiness_Update_Column {
  /** column name */
  Ready = 'ready',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['String'];
  last_seen?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  last_seen?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** ordering options when selecting data from "users" */
export type Users_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "users" */
export type Users_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** columns and relationships of "users_to_programs" */
export type Users_To_Programs = {
  __typename?: 'users_to_programs';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  program: Programs;
  program_id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** aggregated selection of "users_to_programs" */
export type Users_To_Programs_Aggregate = {
  __typename?: 'users_to_programs_aggregate';
  aggregate?: Maybe<Users_To_Programs_Aggregate_Fields>;
  nodes: Array<Users_To_Programs>;
};

/** aggregate fields of "users_to_programs" */
export type Users_To_Programs_Aggregate_Fields = {
  __typename?: 'users_to_programs_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Users_To_Programs_Max_Fields>;
  min?: Maybe<Users_To_Programs_Min_Fields>;
};


/** aggregate fields of "users_to_programs" */
export type Users_To_Programs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_To_Programs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users_to_programs" */
export type Users_To_Programs_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Users_To_Programs_Max_Order_By>;
  min?: Maybe<Users_To_Programs_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_to_programs" */
export type Users_To_Programs_Arr_Rel_Insert_Input = {
  data: Array<Users_To_Programs_Insert_Input>;
  on_conflict?: Maybe<Users_To_Programs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_to_programs". All fields are combined with a logical 'AND'. */
export type Users_To_Programs_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Users_To_Programs_Bool_Exp>>>;
  _not?: Maybe<Users_To_Programs_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Users_To_Programs_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  program?: Maybe<Programs_Bool_Exp>;
  program_id?: Maybe<Uuid_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_to_programs" */
export enum Users_To_Programs_Constraint {
  /** unique or primary key constraint */
  UsersToProgramsPkey = 'users_to_programs_pkey'
}

/** input type for inserting data into table "users_to_programs" */
export type Users_To_Programs_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  program?: Maybe<Programs_Obj_Rel_Insert_Input>;
  program_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_To_Programs_Max_Fields = {
  __typename?: 'users_to_programs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  program_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users_to_programs" */
export type Users_To_Programs_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_To_Programs_Min_Fields = {
  __typename?: 'users_to_programs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  program_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users_to_programs" */
export type Users_To_Programs_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  program_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "users_to_programs" */
export type Users_To_Programs_Mutation_Response = {
  __typename?: 'users_to_programs_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Users_To_Programs>;
};

/** input type for inserting object relation for remote table "users_to_programs" */
export type Users_To_Programs_Obj_Rel_Insert_Input = {
  data: Users_To_Programs_Insert_Input;
  on_conflict?: Maybe<Users_To_Programs_On_Conflict>;
};

/** on conflict condition type for table "users_to_programs" */
export type Users_To_Programs_On_Conflict = {
  constraint: Users_To_Programs_Constraint;
  update_columns: Array<Users_To_Programs_Update_Column>;
  where?: Maybe<Users_To_Programs_Bool_Exp>;
};

/** ordering options when selecting data from "users_to_programs" */
export type Users_To_Programs_Order_By = {
  created_at?: Maybe<Order_By>;
  program?: Maybe<Programs_Order_By>;
  program_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "users_to_programs" */
export type Users_To_Programs_Pk_Columns_Input = {
  program_id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** select columns of table "users_to_programs" */
export enum Users_To_Programs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_to_programs" */
export type Users_To_Programs_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  program_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "users_to_programs" */
export enum Users_To_Programs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  UserId = 'user_id'
}

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Name = 'name'
}


/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type InsertCreateStripeCheckoutSessionMutationVariables = Exact<{
  program_id: Scalars['uuid'];
}>;


export type InsertCreateStripeCheckoutSessionMutation = (
  { __typename: 'mutation_root' }
  & { insert_create_stripe_checkout_sessions?: Maybe<(
    { __typename?: 'create_stripe_checkout_sessions_mutation_response' }
    & { returning: Array<(
      { __typename?: 'create_stripe_checkout_sessions' }
      & Pick<Create_Stripe_Checkout_Sessions, 'id'>
    )> }
  )> }
);

export type CreateStripeCheckoutSessionSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type CreateStripeCheckoutSessionSubscription = (
  { __typename?: 'subscription_root' }
  & { create_stripe_checkout_sessions: Array<(
    { __typename?: 'create_stripe_checkout_sessions' }
    & Pick<Create_Stripe_Checkout_Sessions, 'status'>
  )> }
);

export type StripeCheckoutSessionSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type StripeCheckoutSessionSubscription = (
  { __typename?: 'subscription_root' }
  & { stripe_checkout_sessions: Array<(
    { __typename?: 'stripe_checkout_sessions' }
    & Pick<Stripe_Checkout_Sessions, 'stripe_checkout_session_id'>
  )> }
);

export type SubscribeToFreeCheckoutSessionCreationSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type SubscribeToFreeCheckoutSessionCreationSubscription = (
  { __typename?: 'subscription_root' }
  & { create_free_checkout_sessions: Array<(
    { __typename?: 'create_free_checkout_sessions' }
    & Pick<Create_Free_Checkout_Sessions, 'status' | 'over'>
  )> }
);

export type InsertCreateFreeCheckoutSessionsMutationVariables = Exact<{
  program_id: Scalars['uuid'];
}>;


export type InsertCreateFreeCheckoutSessionsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_create_free_checkout_sessions?: Maybe<(
    { __typename?: 'create_free_checkout_sessions_mutation_response' }
    & { returning: Array<(
      { __typename?: 'create_free_checkout_sessions' }
      & Pick<Create_Free_Checkout_Sessions, 'id'>
      & { program: (
        { __typename?: 'programs' }
        & Pick<Programs, 'program_name'>
        & { coach: (
          { __typename?: 'coaches' }
          & Pick<Coaches, 'name'>
        ) }
      ) }
    )> }
  )> }
);

export type IdentifyToHasuraPublicationMutationVariables = Exact<{
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
}>;


export type IdentifyToHasuraPublicationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & Pick<Users_Mutation_Response, 'affected_rows'>
  )> }
);

export type GetUserProgramsQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type GetUserProgramsQuery = (
  { __typename?: 'query_root' }
  & { users_to_programs: Array<(
    { __typename?: 'users_to_programs' }
    & { program: (
      { __typename?: 'programs' }
      & Pick<Programs, 'id' | 'program_name'>
      & { coach: (
        { __typename?: 'coaches' }
        & Pick<Coaches, 'name'>
      ) }
    ) }
  )> }
);


export const InsertCreateStripeCheckoutSession = gql`
    mutation InsertCreateStripeCheckoutSession($program_id: uuid!) {
  __typename
  insert_create_stripe_checkout_sessions(objects: {program_id: $program_id}) {
    returning {
      id
    }
  }
}
    `;
export const CreateStripeCheckoutSession = gql`
    subscription CreateStripeCheckoutSession($id: uuid!) {
  create_stripe_checkout_sessions(where: {id: {_eq: $id}}) {
    status
  }
}
    `;
export const StripeCheckoutSession = gql`
    subscription StripeCheckoutSession($id: uuid!) {
  stripe_checkout_sessions(where: {id: {_eq: $id}}) {
    stripe_checkout_session_id
  }
}
    `;
export const SubscribeToFreeCheckoutSessionCreation = gql`
    subscription SubscribeToFreeCheckoutSessionCreation($id: uuid!) {
  create_free_checkout_sessions(where: {id: {_eq: $id}}) {
    status
    over
  }
}
    `;
export const InsertCreateFreeCheckoutSessions = gql`
    mutation InsertCreateFreeCheckoutSessions($program_id: uuid!) {
  insert_create_free_checkout_sessions(objects: {program_id: $program_id}) {
    returning {
      id
      program {
        coach {
          name
        }
        program_name
      }
    }
  }
}
    `;
export const IdentifyToHasuraPublication = gql`
    mutation IdentifyToHasuraPublication($email: String!, $name: String) {
  insert_users(objects: {email: $email, name: $name}, on_conflict: {constraint: users_pkey, update_columns: name}) {
    affected_rows
  }
}
    `;
export const GetUserPrograms = gql`
    query GetUserPrograms($user_id: String!) {
  users_to_programs(where: {user_id: {_eq: $user_id}}, order_by: {created_at: desc}) {
    program {
      id
      program_name
      coach {
        name
      }
    }
  }
}
    `;