export interface CheckoutState {
  isCheckingOut: boolean;
  stripeCheckoutSessionId: null | string;
  error: boolean;
  status: string;
}

export interface CheckoutSlice {
  byId: {
    [id: string]: CheckoutState;
  };
  allIds: string[];
}

export enum CheckoutActionTypes {
  CHECKOUT_PROGRAM_START = "CHECKOUT_PROGRAM_START",
  CHECKOUT_PROGRAM_SUCCESS = "CHECKOUT_PROGRAM_SUCCESS",
  CHECKOUT_PROGRAM_FAILURE = "CHECKOUT_PROGRAM_FAILURE",
}

export interface ActionCheckoutProgramStartPayload {
  programId: string;
  email: string;
}
export interface ActionCheckoutProgramStart {
  type: CheckoutActionTypes.CHECKOUT_PROGRAM_START;
  payload: ActionCheckoutProgramStartPayload;
}

export interface ActionCheckoutProgramSuccessPayload {
  programId: string;
  email: string;
  stripeCheckoutSessionId: string;
  status: string;
}
export interface ActionCheckoutProgramSuccess {
  type: CheckoutActionTypes.CHECKOUT_PROGRAM_SUCCESS;
  payload: ActionCheckoutProgramSuccessPayload;
}

export interface ActionCheckoutProgramFailurePayload {
  error: Error;
  programId: string;
  email: string;
  status: string;
}
export interface ActionCheckoutProgramFailure {
  type: CheckoutActionTypes.CHECKOUT_PROGRAM_FAILURE;
  payload: ActionCheckoutProgramFailurePayload;
}

export type CheckoutActions =
  | ActionCheckoutProgramStart
  | ActionCheckoutProgramSuccess
  | ActionCheckoutProgramFailure;

export default CheckoutActionTypes;
