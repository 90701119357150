import {
  all,
  takeEvery,
  call,
  put,
  take,
  select,
  delay,
  race,
} from "typed-redux-saga";
import MyProgramsActionTypes, { UserToProgram } from "./my-programs.types";
import {
  actionGetMyProgramsSuccess,
  actionGetMyProgramsFailure,
  actionGetMyProgramsStart,
} from "./my-programs.actions";
import gql from "graphql-tag";
import { selectUserUid } from "../auth/auth.selectors";
import Sentry from "../../third-party/sentry";
import hasuraPublications from "../../hasura-client";
import { waitForValidToken } from "../auth/auth.sagas";
import { AuthActionTypes } from "../auth/auth.types";
import {
  GetUserProgramsQuery,
  GetUserProgramsQueryVariables,
  GetUserPrograms,
} from "../../graphql/donotskip-publications.types";

export const getUserProgramsAsync = async (user_id: string) => {
  const { data } = await hasuraPublications.query<
    GetUserProgramsQuery,
    GetUserProgramsQueryVariables
  >({
    query: GetUserPrograms,
    variables: {
      user_id: user_id,
    },
    fetchPolicy: "network-only",
  });

  return data;
};

export function* getMyProgramsStart() {
  try {
    yield* call(waitForValidToken);
    const user_id = yield* select(selectUserUid);

    if (!user_id) {
      throw Error("User ID is not defined!");
    }

    const data = yield* call(getUserProgramsAsync, user_id);

    const users_to_programs = data.users_to_programs as UserToProgram[];

    yield* put(actionGetMyProgramsSuccess({ users_to_programs }));
  } catch (error) {
    Sentry.captureException(error);
    yield* put(actionGetMyProgramsFailure({ error }));
  }
}

export function* onGetMyProgramsStart() {
  yield* takeEvery(
    MyProgramsActionTypes.GET_MY_PROGRAMS_START,
    getMyProgramsStart
  );
}

export function* onUserConnection() {
  while (true) {
    yield* race([take(AuthActionTypes.FIREBASE_REFRESH_USER_SUCCESS)]);
    yield* put(actionGetMyProgramsStart());
  }
}

export function* myProgramsSagas() {
  yield* all([call(onGetMyProgramsStart), call(onUserConnection)]);
}
