export enum HasuraPublicationActionTypes {
  IDENTIFY_TO_HASURA_PUBLICATION_START = "IDENTIFY_TO_HASURA_PUBLICATION_START",
  IDENTIFY_TO_HASURA_PUBLICATION_SUCCESS = "IDENTIFY_TO_HASURA_PUBLICATION_SUCCESS",
  IDENTIFY_TO_HASURA_PUBLICATION_FAILURE = "IDENTIFY_TO_HASURA_PUBLICATION_FAILURE",
}

export interface ActionIdentifyToHasuraPublicationStartPayload {
  userId: string;
  token: string;
  email: string | null;
  name: string | null;
}
export interface ActionIdentifyToHasuraPublicationStart {
  type: HasuraPublicationActionTypes.IDENTIFY_TO_HASURA_PUBLICATION_START;
  payload: ActionIdentifyToHasuraPublicationStartPayload;
}

export interface ActionIdentifyToHasuraPublicationSuccessPayload {
  userId: string;
}
export interface ActionIdentifyToHasuraPublicationSuccess {
  type: HasuraPublicationActionTypes.IDENTIFY_TO_HASURA_PUBLICATION_SUCCESS;
  payload: ActionIdentifyToHasuraPublicationSuccessPayload;
}

export interface ActionIdentifyToHasuraPublicationFailurePayload {
  userId: string;
  error: Error;
}
export interface ActionIdentifyToHasuraPublicationFailure {
  type: HasuraPublicationActionTypes.IDENTIFY_TO_HASURA_PUBLICATION_FAILURE;
  payload: ActionIdentifyToHasuraPublicationFailurePayload;
}

export type HasuraPublicationsActions =
  | ActionIdentifyToHasuraPublicationStart
  | ActionIdentifyToHasuraPublicationSuccess
  | ActionIdentifyToHasuraPublicationFailure;

export default HasuraPublicationActionTypes;
