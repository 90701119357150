import { combineReducers } from "redux";
import authReducer from "./auth/auth.reducer";
import loadingReducer from "./loading/loading.reducer";
import stripeReducer from "./stripe/stripe.reducer";
import checkoutReducer from "./checkout/checkout.reducer";
import hasuraPublicationsReducer from "./hasura-publications/hasura-publications.reducer";
import { persistReducer } from "redux-persist";
import myProgramsReducer from "./my-programs/my-programs.reducer";
import freeCheckoutReducer from "./free-checkout/free-checkout.reducer";
import { storage, myPersistConfig } from "./storage";
import { STORES } from "./stores";
import allProgramsReducer from "./all-programs/all-programs.reducer";
import languageReducer from "./language/language.reducer";

const authManagerConfig = {
  key: STORES.AUTH_MANAGER,
  storage: storage,
};

const rootReducer = combineReducers({
  [STORES.AUTH_MANAGER]: myPersistConfig(authReducer, authManagerConfig),
  [STORES.LOADING_MANAGER]: loadingReducer,
  [STORES.STRIPE_MANAGER]: stripeReducer,
  [STORES.CHECKOUT_MANAGER]: checkoutReducer,
  [STORES.HASURA_PUBLICATIONS_MANAGER]: hasuraPublicationsReducer,
  [STORES.MY_PROGRAMS]: myProgramsReducer,
  [STORES.FREE_CHECKOUT_MANAGER]: freeCheckoutReducer,
  [STORES.ALL_PROGRAMS]: allProgramsReducer,
  [STORES.LANGUAGE]: languageReducer,
});

export default rootReducer;
