import { all, call } from "typed-redux-saga";
import { authSagas } from "./auth/auth.sagas";
import { checkoutSagas } from "./checkout/checkout.sagas";
import { hasuraPublicationsSagas } from "./hasura-publications/hasura-publications.sagas";
import { myProgramsSagas } from "./my-programs/my-programs.sagas";
import { freeCheckoutSagas } from "./free-checkout/free-checkout.sagas";
import { allProgramsSagas } from "./all-programs/all-programs.sagas";

export default function* rootSaga() {
  yield* all([
    call(authSagas),
    call(checkoutSagas),
    call(hasuraPublicationsSagas),
    call(myProgramsSagas),
    call(freeCheckoutSagas),
    call(allProgramsSagas),
  ]);
}
