import { STORES } from "./stores";
import { persistReducer, PersistConfig } from "redux-persist";

export const storage = process.browser
  ? require("redux-persist/lib/storage").default
  : null;

export const myPersistConfig = <S>(reducer: any, config: PersistConfig<S>) => {
  return process.browser ? persistReducer(config, reducer) : reducer;
};
