import {
  ActionAllProgramsFetchStartPayload,
  ActionAllProgramsFetchStart,
  AllProgramsActionTypes,
  ActionAllProgramsFetchSuccessPayload,
  ActionAllProgramsFetchSuccess,
  ActionAllProgramsFetchFailurePayload,
  ActionAllProgramsFetchFailure,
} from "./all-programs.types";

export const actionAllProgramsFetchStart = (
  payload: ActionAllProgramsFetchStartPayload
): ActionAllProgramsFetchStart => ({
  type: AllProgramsActionTypes.ALL_PROGRAMS_FETCH_START,
  payload,
});

export const actionAllProgramsFetchSuccess = (
  payload: ActionAllProgramsFetchSuccessPayload
): ActionAllProgramsFetchSuccess => ({
  type: AllProgramsActionTypes.ALL_PROGRAMS_FETCH_SUCCESS,
  payload,
});

export const actionAllProgramsFetchFailure = (
  payload: ActionAllProgramsFetchFailurePayload
): ActionAllProgramsFetchFailure => ({
  type: AllProgramsActionTypes.ALL_PROGRAMS_FETCH_FAILURE,
  payload,
});
