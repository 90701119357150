import produce from "immer";
import CheckoutActionTypes, {
  CheckoutSlice,
  CheckoutState,
  CheckoutActions,
} from "./checkout.types";
import { HydrateActions } from "../helper";
import { HYDRATE } from "next-redux-wrapper";
import AuthActionTypes, { AuthActions } from "../auth/auth.types";

const checkoutState: CheckoutState = {
  isCheckingOut: false,
  stripeCheckoutSessionId: null,
  error: false,
  status: "",
};

const INITIAL_STATE: CheckoutSlice = {
  byId: {},
  allIds: [],
};

const checkoutReducer = (
  state = INITIAL_STATE,
  action: CheckoutActions | HydrateActions | AuthActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HYDRATE: {
        break;
      }
      case CheckoutActionTypes.CHECKOUT_PROGRAM_START: {
        const { programId } = action.payload;

        draft.byId[programId] = {
          isCheckingOut: true,
          stripeCheckoutSessionId: null,
          error: false,
          status: "NOT_STARTED",
        };

        draft.allIds.push(programId);

        break;
      }
      case CheckoutActionTypes.CHECKOUT_PROGRAM_SUCCESS: {
        const { stripeCheckoutSessionId, programId, status } = action.payload;

        draft.byId[programId] = {
          isCheckingOut: false,
          stripeCheckoutSessionId: stripeCheckoutSessionId,
          error: false,
          status: "NOT_STARTED",
        };

        break;
      }
      case CheckoutActionTypes.CHECKOUT_PROGRAM_FAILURE: {
        const { programId, status } = action.payload;

        draft.byId[programId] = {
          isCheckingOut: false,
          stripeCheckoutSessionId: null,
          error: true,
          status: status,
        };

        break;
      }
      case AuthActionTypes.LOGOUT_SUCCESS: {
        return INITIAL_STATE;
      }
      default:
        break;
    }
  });

export default checkoutReducer;
