import {
  all,
  takeEvery,
  call,
  put,
  take,
  select,
  delay,
  putResolve,
  race,
} from "typed-redux-saga";
import AuthActionTypes, {
  ActionAuthLoginStart,
  ActionRefreshFirebaseUserStart,
} from "./auth.types";
import {
  actionAuthLoginFailure,
  actionAuthLoginSuccess,
  actionRefreshTokenStart,
  actionRefreshTokenSuccess,
  actionRefreshTokenFailure,
  actionRefreshFirebaseUserSuccess,
  actionRefreshFirebaseUserFailure,
  actionAuthLogoutStart,
} from "./auth.actions";
import { eventChannel, SagaIterator } from "redux-saga";
import firebase, { User } from "firebase";
import {
  selectUser,
  selectTokenExpirationTime,
  selectTokenIsValid,
} from "./auth.selectors";
import moment from "moment";
import Sentry from "../../third-party/sentry";
import { snackbarRef } from "../../third-party/snackBarService";
import { i18n } from "../../../i18n";

export function* waitForValidToken(timeout: number = 10000) {
  let timeoutCounter = 0;
  const step = 5000;
  let validToken = yield* select(selectTokenIsValid(new Date()));
  while (!validToken && timeoutCounter < timeout) {
    const [delayVal] = yield* race([
      delay(step, step),
      take(AuthActionTypes.LOGIN_SUCCESS),
      take(AuthActionTypes.REFRESH_TOKEN_SUCCESS),
      take(AuthActionTypes.FIREBASE_REFRESH_USER_SUCCESS),
    ]);

    if (delayVal) {
      timeoutCounter += delayVal as number;
    }

    validToken = yield* select(selectTokenIsValid(new Date()));
  }

  if (timeoutCounter >= timeout) {
    throw Error("Timeout waiting for valid token!");
  }
}

export const firestoreListener = (user: firebase.User) =>
  eventChannel<firebase.auth.IdTokenResult | Error>((emitter) => {
    let unsubscribe: null | (() => void) = null;
    try {
      const onSnapshotCallback = async (
        doc: firebase.firestore.DocumentSnapshot
      ) => {
        // Force refresh to pick up the latest custom claims changes.
        // Have to call getIdToken twice to make it work
        const data = doc.data();

        if (!data) {
          return;
        }

        if (data) {
          if (data.refreshTime) {
            const tokenResults = await user.getIdTokenResult(true);
            if (tokenResultsHasHasuraClaims(tokenResults)) {
              emitter(tokenResults);
            }
          }
        }
      };

      unsubscribe = firebase
        .firestore()
        .doc(`token-metadata/${user.uid}`)
        .onSnapshot({
          next: onSnapshotCallback,
          error: (error) => {
            emitter(new Error(error.message));
          },
        });
    } catch (error) {
      emitter(new Error(error.message));
    } finally {
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  });

export const tokenResultsHasHasuraClaims = (
  tokenResults: firebase.auth.IdTokenResult
) => {
  const hasuraClaim = tokenResults.claims["https://hasura.io/jwt/claims"];
  return !!hasuraClaim;
};

export function* loginStart({ payload }: ActionAuthLoginStart) {
  const { user } = payload;
  if (user) {
    try {
      const idTokenResult: firebase.auth.IdTokenResult = yield* call(
        [user, user.getIdTokenResult],
        true
      );
      const hasHasuraClaims = tokenResultsHasHasuraClaims(idTokenResult);

      if (hasHasuraClaims) {
        yield* put(
          actionAuthLoginSuccess({ user, tokenResults: idTokenResult })
        );
      } else {
        const chan = yield* call(firestoreListener, user);
        const { task: tokenResults, timeout } = yield* race({
          task: take(chan),
          timeout: delay(10000),
        });
        chan.close();
        if (timeout) {
          throw Error("Timeout when login in");
        }
        yield* put(
          actionAuthLoginSuccess({
            user,
            tokenResults: tokenResults as firebase.auth.IdTokenResult,
          })
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      yield* put(actionAuthLoginFailure({ error }));
    }
  }
}

export function* refreshToken() {
  try {
    const user = (yield* select(selectUser)) as User | undefined;

    if (!user) {
      throw Error("Cannot refresh token because user is undefined");
    }

    const tokenResults = yield* call([user, user.getIdTokenResult], true);

    yield* put(actionRefreshTokenSuccess({ tokenResults }));
  } catch (error) {
    Sentry.captureException(error);
    yield* put(actionRefreshTokenFailure({ error }));
  }
}

export function* onAuthLoginStart() {
  yield* takeEvery(AuthActionTypes.LOGIN_START, loginStart);
}

export function* onRefreshTokenStart() {
  yield* takeEvery(AuthActionTypes.REFRESH_TOKEN_START, refreshToken);
}

export function* tokenRefresher() {
  const tenMinutesInMs = moment.duration({ minutes: 10 }).asMilliseconds();

  yield* take(AuthActionTypes.START_TOKEN_REFRESHER);

  let user = (yield* select(selectUser)) as User | undefined;

  while (!user) {
    yield* race([
      take(AuthActionTypes.LOGIN_SUCCESS),
      take(AuthActionTypes.FIREBASE_REFRESH_USER_SUCCESS),
    ]);
    user = yield* select(selectUser);
  }

  while (!user?.getIdTokenResult) {
    yield* race([
      take(AuthActionTypes.LOGIN_SUCCESS),
      take(AuthActionTypes.FIREBASE_REFRESH_USER_SUCCESS),
    ]);
    user = yield* select(selectUser);
  }

  while (true) {
    const expirationTime = (yield* select(
      selectTokenExpirationTime
    )) as Date | null;

    if (!expirationTime) {
      yield* delay(10000);
      continue;
    }

    if (expirationTime.getTime() <= Date.now()) {
      yield* putResolve(actionRefreshTokenStart());
    } else {
      let nbMillisecondsBeforeExpiry = expirationTime.getTime() - Date.now();

      if (nbMillisecondsBeforeExpiry < tenMinutesInMs) {
        // refresh
        yield* put(actionRefreshTokenStart());
      } else {
        while (nbMillisecondsBeforeExpiry > tenMinutesInMs) {
          // shedule refresh
          const nbMilliseconds = moment
            .duration({ seconds: 10 })
            .asMilliseconds();
          // console.log(
          //   `Next token check planned in ${nbMilliseconds} milliseconds. Nb MS before expiry = ${nbMillisecondsBeforeExpiry}`
          // );
          yield* delay(nbMilliseconds);
          nbMillisecondsBeforeExpiry = expirationTime.getTime() - Date.now();
        }
      }

      yield* putResolve(actionRefreshTokenStart());
      yield* delay(10000);
    }
  }
}

export function* refreshFirebaseUserStart({
  payload,
}: ActionRefreshFirebaseUserStart) {
  const { user } = payload;
  if (user) {
    try {
      const idTokenResult = yield* call([user, user.getIdTokenResult], true);
      const hasuraClaim = idTokenResult.claims["https://hasura.io/jwt/claims"];

      if (hasuraClaim) {
        yield* put(actionRefreshFirebaseUserSuccess({ user, idTokenResult }));
      } else {
        const chan = yield* call(firestoreListener, user);
        const tokenResults = (yield* take(chan)) as firebase.auth.IdTokenResult;
        chan.close();
        yield* put(
          actionRefreshFirebaseUserSuccess({
            user,
            idTokenResult: tokenResults,
          })
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      yield* put(actionRefreshFirebaseUserFailure({ error }));
    }
  }
}

export function* onRefreshFirebaseUserStart() {
  yield* takeEvery(
    AuthActionTypes.FIREBASE_REFRESH_USER_START,
    refreshFirebaseUserStart
  );
}

export function* onLoginFailure() {
  yield* takeEvery(AuthActionTypes.LOGIN_FAILURE, function* () {
    snackbarRef.enqueueSnackbar(i18n.t("Error while logging in"), {
      variant: "error",
    });
    yield* put(actionAuthLogoutStart());
  });
}

export function* authSagas() {
  yield* all([
    call(onAuthLoginStart),
    call(onRefreshTokenStart),
    call(tokenRefresher),
    call(onRefreshFirebaseUserStart),
    call(onLoginFailure),
  ]);
}
