import React, { useEffect } from "react";
import App, { AppProps, AppInitialProps } from "next/app";
import CssBaseline from "@material-ui/core/CssBaseline";
import { wrapper } from "../src/redux/store";
import {
  useDispatch,
  useSelector,
  ReactReduxContext,
  Provider,
} from "react-redux";
import Router from "next/router";
import { actionSetIsLoading } from "../src/redux/loading/loading.actions";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../src/theme";
import { selectStripeClient } from "../src/redux/stripe/stripe.selectors";
import { PersistGate } from "redux-persist/integration/react";
import withReduxSaga from "next-redux-saga";
import {
  actionStartTokenRefresher,
  actionAuthLoginStart,
  actionRefreshFirebaseUserStart,
  actionAuthLogoutStart,
} from "../src/redux/auth/auth.actions";
import firebaseApp from "../firebase";
import Head from "next/head";
import * as Sentry from "@sentry/node";
import { NextComponentType } from "next";
import { selectUserUid } from "../src/redux/auth/auth.selectors";
import { SentryDSN, setSentryScopeUser } from "../src/third-party/sentry";
import i18n, { appWithTranslation } from "../i18n";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "../src/third-party/snackBarService";
import Footer from "../src/components/Footer/footer";
import { Box } from "@material-ui/core";
import "../src/styles/default.css";
import { selectCurrentLanguage } from "../src/redux/language/language.selector";
import { pageview } from "../src/third-party/analytics";

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: SentryDSN,
});

const siteTitle = "DoNotSkip Programs";

interface MyReduxAppProps {
  Component: NextComponentType;
  pageProps: any;
}
const MyReduxApp: React.FC<MyReduxAppProps> = ({ Component, pageProps }) => {
  const dispatch = useDispatch();
  const stripe = useSelector(selectStripeClient);
  const userId = useSelector(selectUserUid);
  const languageId = useSelector(selectCurrentLanguage);

  useEffect(() => {
    i18n.i18n.changeLanguage(languageId);
  }, []);

  useEffect(() => {
    const authUnsubscribeCallback = firebaseApp
      .auth()
      .onAuthStateChanged((_user) => {
        if (_user) {
          setSentryScopeUser(_user);

          if (!userId) {
            dispatch(actionAuthLoginStart({ user: _user }));
          } else {
            dispatch(actionRefreshFirebaseUserStart({ user: _user }));
          }
        } else {
          dispatch(actionAuthLogoutStart());
        }
      });

    return () => {
      authUnsubscribeCallback();
    };
  }, [userId]);

  useEffect(() => {
    if (window) {
      dispatch(actionStartTokenRefresher());
    }
  }, []);

  useEffect(() => {
    const onRouteChangeStartCallback = () => {
      dispatch(actionSetIsLoading({ isLoading: true }));
    };
    const onRouteChangeCompleteCallback = (url: string) => {
      dispatch(actionSetIsLoading({ isLoading: false }));
      pageview(url);
    };
    const onRouteChangeErrorCallback = () => {
      dispatch(actionSetIsLoading({ isLoading: false }));
    };

    const onUnhandledRejection = (err: any) => {
      Sentry.captureException(err);
    };

    const onUncaughtException = (err: any) => {
      Sentry.captureException(err);
    };

    process.on("unhandledRejection", onUnhandledRejection);
    process.on("uncaughtException", onUncaughtException);

    Router.events.on("routeChangeStart", onRouteChangeStartCallback);
    Router.events.on("routeChangeComplete", onRouteChangeCompleteCallback);
    Router.events.on("routeChangeError", onRouteChangeErrorCallback);

    return () => {
      Router.events.off("routeChangeStart", onRouteChangeStartCallback);
      Router.events.off("routeChangeComplete", onRouteChangeCompleteCallback);
      Router.events.off("routeChangeError", onRouteChangeErrorCallback);

      process.off("unhandledRejection", onUnhandledRejection);
      process.off("uncaughtException", onUncaughtException);
    };
  }, []);

  return <Component {...pageProps} />;
};

interface MyAppProps extends AppProps {
  store: any;
}

const MyMainComponent: React.FC<MyAppProps> = ({ Component, pageProps }) => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <SnackbarUtilsConfigurator />
        <Head>
          <title>{siteTitle}</title>
        </Head>
        <CssBaseline />
        <MyReduxApp Component={Component} pageProps={pageProps} />
      </SnackbarProvider>
    </ThemeProvider>
  );
};

class MyApp extends App<AppInitialProps> {
  componentDidMount() {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles && jssStyles.parentNode)
      jssStyles.parentNode.removeChild(jssStyles);
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope((scope) => {
      // Object.keys(errorInfo).forEach((key) => {
      //   scope.setExtra(key, errorInfo[key]);
      // });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps, store } = this.props as MyAppProps;

    return process.browser ? (
      <ReactReduxContext.Consumer>
        {({ store }: { store: any }) => {
          return (
            <PersistGate persistor={store.__PERSISTOR}>
              <MyMainComponent store={store} {...this.props} />
            </PersistGate>
          );
        }}
      </ReactReduxContext.Consumer>
    ) : (
      <ReactReduxContext.Consumer>
        {({ store }: { store: any }) => {
          return (
            <Provider store={store}>
              <MyMainComponent store={store} {...this.props} />
            </Provider>
          );
        }}
      </ReactReduxContext.Consumer>
    );
  }
}

export default wrapper.withRedux(withReduxSaga(appWithTranslation(MyApp)));
