import produce from "immer";
import LoadingActionTypes, {
  LoadingSlice,
  LoadingActions,
} from "./loading.types";
import { HydrateActions } from "../helper";
import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE: LoadingSlice = {
  isLoading: false,
};

const loadingReducer = (
  state = INITIAL_STATE,
  action: LoadingActions | HydrateActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HYDRATE: {
        break;
      }
      case LoadingActionTypes.SET_IS_LOADING: {
        const { isLoading } = action.payload;
        draft.isLoading = isLoading;
        break;
      }
      default: {
        break;
      }
    }
  });

export default loadingReducer;
