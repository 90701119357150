import FreeCheckoutActionTypes, {
  ActionFreeCheckoutProgramStartPayload,
  ActionFreeCheckoutProgramStart,
  ActionFreeCheckoutProgramSuccessPayload,
  ActionFreeCheckoutProgramSuccess,
  ActionFreeCheckoutProgramFailurePayload,
  ActionFreeCheckoutProgramFailure,
  ActionSetFreeCheckoutStatusPayload,
  ActionSetFreeCheckoutStatus,
} from "./free-checkout.types";

export const actionFreeCheckoutStart = (
  payload: ActionFreeCheckoutProgramStartPayload
): ActionFreeCheckoutProgramStart => ({
  type: FreeCheckoutActionTypes.FREE_CHECKOUT_PROGRAM_START,
  payload,
});

export const actionFreeCheckoutSuccess = (
  payload: ActionFreeCheckoutProgramSuccessPayload
): ActionFreeCheckoutProgramSuccess => ({
  type: FreeCheckoutActionTypes.FREE_CHECKOUT_PROGRAM_SUCCESS,
  payload,
});

export const actionFreeCheckoutFailure = (
  payload: ActionFreeCheckoutProgramFailurePayload
): ActionFreeCheckoutProgramFailure => ({
  type: FreeCheckoutActionTypes.FREE_CHECKOUT_PROGRAM_FAILURE,
  payload,
});

export const actionSetFreeCheckoutStatus = (
  payload: ActionSetFreeCheckoutStatusPayload
): ActionSetFreeCheckoutStatus => ({
  type: FreeCheckoutActionTypes.SET_FREE_CHECKOUT_PROGRAM_STATUS,
  payload,
});
