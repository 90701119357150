class CheckoutError extends Error {
  status: string;
  date: Date;
  constructor(status: string, ...params: any) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CheckoutError);
    }

    this.name = "CheckoutError";
    // Custom debugging information
    this.status = status;
    this.date = new Date();
  }
}

export default CheckoutError;
